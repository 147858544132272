import { CaseReducer, createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, ConvertersGroup } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface PartialConvertersSlice extends GenericStoreSlice {
  partialConverters: ConvertersGroup[];
}

type PartialConvertersReducer = GenericStoreReducer<PartialConvertersSlice> & {
  clearPartialConverters: CaseReducer<PartialConvertersSlice, { type: string }>;
};

const fetchPartialConvertersSlice = createSlice<PartialConvertersSlice, PartialConvertersReducer>({
  name: 'partialConverters',
  initialState: {
    partialConverters: [],
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      partialConverters: payload,
    })),
    clearPartialConverters: state => ({
      ...state,
      partialConverters: [],
    }),
  },
});

export const {
  pending: fetchPartialConvertersAction,
  success: fetchPartialConvertersSuccessAction,
  failure: fetchPartialConvertersFailureAction,
  clearPartialConverters,
} = fetchPartialConvertersSlice.actions;

export const fetchPartialConverters =
  (): GenericThunk => async (dispatch, getState, httpClient) => {
    try {
      dispatch(fetchPartialConvertersAction());
      const partialConverters = await httpClient.get(`${apiHostname}/api/converters/partial`);
      dispatch(fetchPartialConvertersSuccessAction(partialConverters.data));
    } catch (error) {
      dispatch(
        fetchPartialConvertersFailureAction((error as AlphamartHttpError)?.response?.data.message),
      );
      return Promise.reject(error);
    }
  };

export default fetchPartialConvertersSlice.reducer;
