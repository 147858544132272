import React, { useCallback, useEffect } from 'react';
import { OptionsProps } from '@alpha-recycling/component-library';
import { useFormik } from 'formik';
import { capitalize, debounce } from 'lodash';
import * as yup from 'yup';

import {
  FilterFieldInputRange,
  RangeValue,
} from 'components/shared/Fields/FieldInputRange/FieldInputRange';
import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FiltersForm } from 'components/shared/forms/FiltersForm/FiltersForm';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { getYearOptions } from 'helpers/dateTime/dateTime';
import { DEFAULT_FILTERS, ENGINE_TYPES } from 'shared/constants';
import { useMediaQuery } from 'shared/hooks';
import { ListModule } from 'shared/types';
import { fetchMakes } from 'store/makesSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import { useGetModels } from '../../../../shared/queries';
import { TypedIntlShape, useTypedIntl, VehiclesMessages } from '../locale/messages';

export interface VehicleFiltersShape {
  makeId: number | null;
  modelId: number | null;
  engineType: string | null;
  year: string | null;
  numberOfConverters: number | null;
  engineDisplacement: {
    from: number | null;
    to: number | null;
  };
}

const getValidationSchema = (intl: TypedIntlShape) =>
  yup.object().shape({
    engineDisplacement: yup.object().shape({
      from: yup.mixed().test({
        name: 'isValid',
        message: intl.formatMessage({ id: 'VehiclesList.Filters.Errors.EngineDisplacement' }),
        test(val) {
          if (val == null || this.parent.to == null) {
            return true;
          }
          return val <= this.parent.to;
        },
      }),
      to: yup.mixed(),
    }),
  });

const numberOfConvertersOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(el => ({
  label: String(el),
  value: el,
}));

interface Props {
  onFiltersChanged: (filters: VehicleFiltersShape) => void;
  onFiltersApplied: () => void;
}

export function VehicleListFilters({
  onFiltersChanged,
  onFiltersApplied,
}: Props): React.ReactElement {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();
  const { makes } = useAppSelector(state => state.makes);
  const savedFilters = useAppSelector(state => state.filters.vehicles);
  const { isOpen, modalType } = useAppSelector(state => state.modalForm);
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);

  const formikContext = useFormik<VehicleFiltersShape>({
    initialValues: savedFilters.data,
    validationSchema: getValidationSchema(intl),
    validateOnChange: true,
    onSubmit: () => {},
  });

  const models = useGetModels(
    { module: ListModule.VEHICLES, makeId: formikContext.values.makeId },
    { enabled: !!formikContext.values.makeId },
  );

  useEffect(() => {
    if (isOpen) return;

    formikContext.setValues(savedFilters.data);
  }, [isOpen, modalType]);

  const onFiltersChangeWithValidation = (data: VehicleFiltersShape) => {
    try {
      // we validate schema directly because formik validation is async and formikContext.errors.engineDisplacement.from may show outdated results
      getValidationSchema(intl).validateSync(data);
      onFiltersChanged(data);
      // eslint-disable-next-line
    } catch {}
  };

  useEffect(() => {
    dispatch(fetchMakes({ module: ListModule.VEHICLES }));
  }, []);

  useEffect(() => {
    !isMobile && onFiltersChangeWithValidation(formikContext.values);
  }, [
    formikContext.values.engineType,
    formikContext.values.makeId,
    formikContext.values.modelId,
    formikContext.values.numberOfConverters,
    formikContext.values.year,
  ]);

  const setQueryDataDebounced = useCallback(debounce(onFiltersChangeWithValidation, 500), []);
  useEffect(() => {
    setQueryDataDebounced({ ...formikContext.values });
  }, [formikContext.values.engineDisplacement.from, formikContext.values.engineDisplacement.to]);

  const handleFiltersClear = () => {
    formikContext.setValues(DEFAULT_FILTERS.vehicles);
  };
  const { makeId, modelId, engineType, year, numberOfConverters, engineDisplacement } =
    formikContext.values;
  const makesOptions = makes?.map(makeOption => ({
    label: makeOption.name.toUpperCase(),
    value: makeOption.id,
  }));
  const modelsOptions = models.data!.map(modelOption => ({
    label: modelOption.name.toUpperCase(),
    value: modelOption.id,
  }));

  const engineTypes = Object.values(ENGINE_TYPES).map(engine => ({
    label: intl.formatMessage({
      id: `VehicleForm.EngineType.${capitalize(engine)}` as keyof VehiclesMessages,
    }),
    value: engine,
  }));

  const yearOptions = getYearOptions();
  const handleDisplacementChange = (value: RangeValue) => {
    formikContext.setFieldValue('engineDisplacement', value);
  };

  const onFiltersApply = () => {
    onFiltersChanged(formikContext.values);
    onFiltersApplied();
  };

  const handleMakeChange = (value: OptionsProps) => {
    !value && formikContext.setFieldValue('modelId', null);
  };

  return (
    <FiltersForm
      context={formikContext}
      onFiltersClear={handleFiltersClear}
      onFiltersApply={onFiltersApply}
      savedFilters={savedFilters}
    >
      <FiltersSection>
        <FieldSelect
          name="year"
          options={yearOptions}
          label={intl.formatMessage({ id: 'VehiclesList.Filters.Year' })}
          value={year}
        />
        <FieldSelect
          name="makeId"
          options={makesOptions}
          label={intl.formatMessage({ id: 'VehiclesList.Filters.Make' })}
          value={makeId}
          onChange={handleMakeChange}
        />
        <FieldSelect
          name="modelId"
          options={modelsOptions}
          label={intl.formatMessage({ id: 'VehiclesList.Filters.Model' })}
          value={modelId}
          disabled={!makeId}
        />
        <FieldSelect
          name="engineType"
          options={engineTypes}
          label={intl.formatMessage({ id: 'VehiclesList.Filters.EngineType' })}
          value={engineType}
        />
        <FieldSelect
          name="numberOfConverters"
          options={numberOfConvertersOptions}
          label={intl.formatMessage({ id: 'VehiclesList.Filters.NumberOfConverters' })}
          value={numberOfConverters}
        />
      </FiltersSection>
      <FiltersSection label={intl.formatMessage({ id: 'VehiclesList.Filters.EngineDisplacement' })}>
        <FilterFieldInputRange
          onChange={handleDisplacementChange}
          value={engineDisplacement}
          min={0}
          max={1000000}
          error={formikContext?.errors?.engineDisplacement?.from}
        />
      </FiltersSection>
    </FiltersForm>
  );
}
