import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, Converter } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface ConverterDetailsState extends GenericStoreSlice {
  converter: Converter | null;
}

const converterDetailsSlice = createSlice<
  ConverterDetailsState,
  GenericStoreReducer<ConverterDetailsState>
>({
  name: 'converterDetails',
  initialState: {
    converter: null,
    error: undefined,
    isPending: false,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      converter: payload,
    })),
  },
});

export const {
  pending: fetchConverterAction,
  success: fetchConverterSuccessAction,
  failure: fetchConverterFailureAction,
} = converterDetailsSlice.actions;

export const fetchConverter =
  (id: string | number): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      const url = `${apiHostname}/api/converters/${id}`;

      dispatch(fetchConverterAction());
      const { data } = await httpClient.get(url);
      dispatch(fetchConverterSuccessAction(data));
    } catch (error) {
      dispatch(fetchConverterFailureAction((error as AlphamartHttpError)?.response?.data.message));

      return Promise.reject(error);
    }
  };

export const clearConverter = (): ReturnType<typeof fetchConverterSuccessAction> =>
  fetchConverterSuccessAction(null);

export default converterDetailsSlice.reducer;
