import React, { useEffect, useMemo } from 'react';
import { OptionsProps } from '@alpha-recycling/component-library';
import { useFormik } from 'formik';

import { FilterCheckbox } from 'components/shared/Fields/FieldCheckbox';
import { FieldDatePickerRangeRaw } from 'components/shared/Fields/FieldDatePickerRange/FieldDatePickerRange';
import { FieldRangeLayoutRaw } from 'components/shared/Fields/FieldRange/FieldRange';
import { FieldSelect, FieldSelectRaw } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FieldSwitcher } from 'components/shared/Fields/FieldSwitcher/FieldSwitcher';
import { FiltersForm } from 'components/shared/forms/FiltersForm/FiltersForm';
import { FiltersSection } from 'components/shared/forms/FiltersSection/FiltersSection';
import { DEFAULT_FILTERS, FilterableModules, PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useEffectAfterMount, useMediaQuery } from 'shared/hooks';
import { useGetModels } from 'shared/queries';
import { ListModule } from 'shared/types';
import { fetchMakes } from 'store/makesSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { fetchTypes } from 'store/typesSlice';
import { MEDIA_QUERY } from 'theme';
import { useTypedIntl } from '../locale/messages';

export interface ConvertersFiltersShape {
  makeId: number | null;
  modelId: number | null;
  converterType: number | null;
  years?: { from: string | null; to: string | null };
  addedAt: { from: Date | null; to: Date | null };
  picture: string | null;
  showComingSoon?: boolean;
  query?: string;
  searchSession?: string;
  showCounterfeit?: boolean;
}

interface Props {
  onFiltersChanged: (filters: ConvertersFiltersShape) => void;
  onFiltersApplied: () => void;
}

export const ConvertersFilters = ({
  onFiltersChanged,
  onFiltersApplied,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const dispatch = useAppDispatch();
  const { makes } = useAppSelector(state => state.makes);
  const { types } = useAppSelector(state => state.types);
  const savedFilters = useAppSelector(state => state.filters[FilterableModules.CONVERTERS]);
  const formikContext = useFormik<ConvertersFiltersShape>({
    initialValues: savedFilters.data,
    onSubmit: () => {},
  });
  const { setFieldValue, setValues, values, handleChange } = formikContext;
  const isMobile = useMediaQuery(MEDIA_QUERY.MAX_XL);

  useEffect(() => {
    dispatch(fetchMakes({ module: ListModule.CONVERTERS }));
    dispatch(fetchTypes());
  }, []);

  const models = useGetModels(
    { module: ListModule.CONVERTERS, makeId: values.makeId },
    { enabled: !!values.makeId },
  );

  useEffectAfterMount(() => {
    !isMobile && onFiltersChanged(values);
  }, [values]);

  const onFiltersClear = () => {
    setValues(DEFAULT_FILTERS.converters);
    onFiltersChanged(DEFAULT_FILTERS.converters);
  };

  const onYearsClear = () => {
    setFieldValue('years', DEFAULT_FILTERS.converters.years);
    onFiltersChanged({ ...values, years: DEFAULT_FILTERS.converters.years });
  };

  const onAddedAtClear = () => {
    setFieldValue('addedAt', DEFAULT_FILTERS.converters.addedAt);
    onFiltersChanged({ ...values, addedAt: DEFAULT_FILTERS.converters.addedAt });
  };

  const onFiltersApply = () => {
    onFiltersChanged(values);
    onFiltersApplied();
  };

  const makesOptions = makes?.map(make => ({ label: make.name.toUpperCase(), value: make.id }));
  const modelsOptions = models.data!.map(model => ({
    label: model.name.toUpperCase(),
    value: model.id,
  }));
  const typesOptions = types?.map(type => ({ label: type.name, value: type.id }));

  const currentYear = new Date().getFullYear();
  const yearsOptions = useMemo(
    () =>
      [...new Array(31)].map((_, i) => ({
        label: (currentYear - i).toString(),
        value: currentYear - i,
      })),
    [],
  );

  const hasPicturesOptions = [
    {
      label: intl.formatMessage({ id: 'ConvertersList.Filters.PicturesYes' }),
      value: 'YES',
    },
    {
      label: intl.formatMessage({ id: 'ConvertersList.Filters.PicturesNo' }),
      value: 'NO',
    },
  ];

  if (authorize(PERMISSIONS.CONVERTERS.FILTERS_ADVANCED)) {
    hasPicturesOptions.push({
      label: intl.formatMessage({ id: 'ConvertersList.Filters.PicturesOld' }),
      value: 'OLD',
    });
  }

  const yearsClearable = !!(values?.years?.from || values?.years?.to);
  const addedAtClearable = !!(values?.addedAt?.from || values?.addedAt?.to);

  const handleMakeChange = (value: OptionsProps) => {
    !value && formikContext.setFieldValue('modelId', null);
  };

  return (
    <FiltersForm
      context={formikContext}
      onFiltersClear={onFiltersClear}
      onFiltersApply={onFiltersApply}
      savedFilters={savedFilters}
    >
      <FiltersSection
        sectionName="makeModel"
        label={intl.formatMessage({ id: 'ConvertersList.Filters.MakeModel' })}
      >
        <FieldSelect
          name="makeId"
          options={makesOptions}
          label={intl.formatMessage({ id: 'ConvertersList.Filters.Make' })}
          value={formikContext.values.makeId}
          onChange={handleMakeChange}
        />

        <FieldSelect
          name="modelId"
          options={modelsOptions}
          label={intl.formatMessage({ id: 'ConvertersList.Filters.Model' })}
          value={formikContext.values.modelId}
          disabled={!formikContext.values.makeId}
        />
      </FiltersSection>
      <FiltersSection
        sectionName="converterType"
        label={intl.formatMessage({ id: 'ConvertersList.Filters.ConverterType' })}
      >
        <FieldSelectRaw
          name="converterType"
          data-cy="filter-converter-type"
          options={typesOptions}
          value={formikContext.values.converterType}
        />
      </FiltersSection>
      <FiltersSection
        sectionName="years"
        label={intl.formatMessage({ id: 'ConvertersList.Filters.Years' })}
        clearable={yearsClearable}
        onSectionClear={onYearsClear}
      >
        <FieldRangeLayoutRaw
          name="years"
          options={yearsOptions}
          value={formikContext.values.years}
          data-cy="filter-years"
        />
      </FiltersSection>
      {authorize(PERMISSIONS.CONVERTERS.FILTERS_ADVANCED) && (
        <FiltersSection
          sectionName="addedAt"
          label={intl.formatMessage({ id: 'ConvertersList.Filters.AddedAt' })}
          clearable={addedAtClearable}
          onSectionClear={onAddedAtClear}
        >
          <FieldDatePickerRangeRaw
            name="addedAt"
            value={formikContext.values.addedAt}
            dataTestId="filter-added-at"
          />
        </FiltersSection>
      )}
      <FiltersSection
        sectionName="picture"
        label={intl.formatMessage({ id: 'ConvertersList.Filters.ContainPictures' })}
      >
        <FieldSwitcher
          name="picture"
          options={hasPicturesOptions}
          value={formikContext.values.picture}
          requiredValue={false}
          data-cy="filter-picture"
        />
      </FiltersSection>
      <FiltersSection
        sectionName="showOnly"
        label={intl.formatMessage({ id: 'ConvertersList.Filters.ShowOnly' })}
      >
        <FilterCheckbox
          name="showComingSoon"
          checked={values.showComingSoon}
          onChange={handleChange}
        >
          {intl.formatMessage({ id: 'ConvertersList.Filters.ShowComingSoon' })}
        </FilterCheckbox>
        <FilterCheckbox
          name="showCounterfeit"
          checked={values.showCounterfeit}
          onChange={handleChange}
        >
          {intl.formatMessage({ id: 'ConvertersList.Filters.ShowPossibleCounterfeit' })}
        </FilterCheckbox>
      </FiltersSection>
    </FiltersForm>
  );
};
