import { PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useCurrentUser, useUserActions } from 'shared/hooks';
import { ItemAction, User, UserListItem } from 'shared/types';
import { useTypedIntl } from '../locale/messages';

export type UserUnion = User | UserListItem;

export const useUserItemActions = (
  userToPerformActionOn?: UserUnion,
): ItemAction<UserUnion | undefined, UserUnion | undefined>[] => {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const currentUser = useCurrentUser();
  const {
    handleUpdate,
    handleHedgeLogsModalOpen,
    handleConverterLogsModalOpen,
    handleThresholdsModalOpen,
    handleShoppingCartActivityLogsModalOpen,
  } = useUserActions();

  const wrap =
    <T,>(cb: (user: UserUnion) => T) =>
    (item: UserUnion | undefined): T =>
      cb((userToPerformActionOn ?? item)!);

  return [
    {
      id: 1,
      label: intl.formatMessage({ id: 'Global.Update' }),
      onClick: wrap(item => handleUpdate(item)),
      dataCy: wrap(item => `update-${item.id}`),
      visible: true,
    },
    {
      id: 3,
      label: intl.formatMessage({ id: 'Global.Thresholds' }),
      onClick: wrap(item =>
        handleThresholdsModalOpen({
          assignedId: item.id,
          assignedName: item.firstName,
        }),
      ),
      dataCy: wrap(item => `usage-thresholds-${item.id}`),
      visible: wrap(
        item => authorize(PERMISSIONS.USERS.EDIT_USAGES_THRESHOLDS) && item.id !== currentUser.id,
      ),
    },
    {
      id: 6,
      label: intl.formatMessage({ id: 'Global.Export.HedgeUseLogs' }),
      onClick: wrap(item => handleHedgeLogsModalOpen({ userId: item.id })),
      visible: () => authorize(PERMISSIONS.COMPANIES.EXPORT_HEDGE_USE_LOGS),
      dataCy: wrap(item => `company-export-hedge-use-logs-${item.id}`),
    },
    {
      id: 7,
      label: intl.formatMessage({ id: 'Global.Export.SearchLogs' }),
      onClick: wrap(item => handleConverterLogsModalOpen({ userId: item.id })),
      visible: () => authorize(PERMISSIONS.COMPANIES.EXPORT_CONVERTER_LOGS),
      dataCy: wrap(item => `company-export-view-search-logs-${item.id}`),
    },
    {
      id: 8,
      label: intl.formatMessage({ id: 'Global.Export.ShoppingCartLogs' }),
      onClick: wrap(item => handleShoppingCartActivityLogsModalOpen({ userId: item.id })),
      visible: () => authorize(PERMISSIONS.USERS.EXPORT_SHOPPING_CART_LOGS),
      dataCy: wrap(item => `export-shopping-cart-logs-${item.id}`),
    },
  ];
};
