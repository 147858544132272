import { ModalFormType } from 'shared/constants';
import { User, UserListItem } from 'shared/types';
import { DownloadUserLogsForm, showModalForm, ThresholdsModalForm } from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { useAlphamartNavigate } from './useAlphamartRouter';

type useUserActionsReturnType = {
  handleUpdate: (user: Pick<User, 'id'>) => void;
  handleHedgeLogsModalOpen: (params: DownloadUserLogsForm) => unknown;
  handleConverterLogsModalOpen: (params: DownloadUserLogsForm) => unknown;
  handleThresholdsModalOpen: (params: ThresholdsModalForm) => unknown;
  handleAccessListModalOpen: (params: UserListItem | User) => unknown;
  handleShoppingCartActivityLogsModalOpen: (params: DownloadUserLogsForm) => unknown;
  isPending: boolean;
};

export function useUserActions(): useUserActionsReturnType {
  const dispatch = useAppDispatch();
  const navigate = useAlphamartNavigate();
  const userDetailsState = useAppSelector(state => state.userDetails);
  const fileDownloadingState = useAppSelector(state => state.downloadFile);
  const userUpdateState = useAppSelector(state => state.updateUser);

  const isPending = !!(
    userDetailsState?.isPending ||
    fileDownloadingState.isPending ||
    userUpdateState.isPending
  );

  const handleUpdate = (user: Pick<User, 'id'>) => navigate(`/users/${user.id}/edit`);

  const handleShoppingCartActivityLogsModalOpen = (params: DownloadUserLogsForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.DownloadShoppingCartActivityLogs, params }));

  const handleHedgeLogsModalOpen = (params: DownloadUserLogsForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.UserHedgeLogs, params }));

  const handleConverterLogsModalOpen = (params: DownloadUserLogsForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.UserConverterLogs, params }));

  const handleThresholdsModalOpen = (params: ThresholdsModalForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.UsageLimit, params }));

  const handleAccessListModalOpen = (params: UserListItem | User) =>
    dispatch(showModalForm({ modalType: ModalFormType.AccessList, params }));

  return {
    handleUpdate,
    handleHedgeLogsModalOpen,
    handleConverterLogsModalOpen,
    handleThresholdsModalOpen,
    handleAccessListModalOpen,
    handleShoppingCartActivityLogsModalOpen,
    isPending,
  };
}
