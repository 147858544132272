import { ModalFormType } from 'shared/constants';
import { Company } from 'shared/types';
import {
  DownloadCompanyLogsExcelForm,
  showModalForm,
  ThresholdsModalForm,
} from 'store/modalFormSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { useAlphamartNavigate } from './useAlphamartRouter';

interface UseCompanyActions {
  actionsPending: boolean;
  handleUpdate: (company: Pick<Company, 'id'>) => void;
  handleHedgeLogsModalOpen: (params: DownloadCompanyLogsExcelForm) => unknown;
  handleConverterLogsModalOpen: (params: DownloadCompanyLogsExcelForm) => unknown;
  handleThresholdsModalOpen: (params: ThresholdsModalForm) => unknown;
}

export const useCompanyActions = (): UseCompanyActions => {
  const navigate = useAlphamartNavigate();
  const dispatch = useAppDispatch();
  const isFileDownloading = useAppSelector(state => state.downloadFile.isPending);
  const actionsPending = !!isFileDownloading;

  const handleHedgeLogsModalOpen = (params: DownloadCompanyLogsExcelForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.HedgeLogs, params }));

  const handleConverterLogsModalOpen = (params: DownloadCompanyLogsExcelForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.ConverterLogs, params }));

  const handleThresholdsModalOpen = (params: ThresholdsModalForm) =>
    dispatch(showModalForm({ modalType: ModalFormType.UsageLimit, params }));

  const handleUpdate = (company: Pick<Company, 'id'>) => navigate(`/companies/${company.id}/edit`);

  return {
    actionsPending,
    handleUpdate,
    handleHedgeLogsModalOpen,
    handleConverterLogsModalOpen,
    handleThresholdsModalOpen,
  };
};
