import React from 'react';
import styled from '@emotion/styled';

import { FieldSwitcherRaw } from 'components/shared/Fields/FieldSwitcher/FieldSwitcher';
import { MEDIA_QUERY, Theme } from 'theme';
import { useTypedIntl } from '../../locale/messages';
import { ChartType } from '../StatisticsPage';

const Container = styled.div<{ theme?: Theme }>`
  width: fit-content;

  button {
    width: fit-content;
  }

  label {
    font-size: 11px;
  }

  @media ${MEDIA_QUERY.MAX_MD} {
    width: 240px;
  }
`;

interface Props {
  selected: ChartType;
  onChartTypeChange: (type: ChartType) => void;
}

export const ChangeTabs = ({ selected, onChartTypeChange }: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const handleChange = value => {
    onChartTypeChange(value as ChartType);
  };
  const options = [
    {
      value: 'view',
      label: intl.formatMessage({
        id: 'Statistics.Chart.Mode.Views',
      }),
    },
    {
      value: 'search',
      label: intl.formatMessage({
        id: 'Statistics.Chart.Mode.Searches',
      }),
    },
  ];

  return (
    <Container>
      <FieldSwitcherRaw
        value={selected}
        name="statisticChartMode"
        options={options}
        onChange={handleChange}
      />
    </Container>
  );
};
