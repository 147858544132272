import { AssayFiltersShape } from 'components/views/Assays/AssaysList/AssaysFilters';
import { CompaniesFiltersShape } from 'components/views/Companies/CompaniesList/CompaniesFilters';
import { ConvertersFiltersShape } from 'components/views/Converters/ConvertersList/ConvertersFilters';
import { DashboardFiltersShape } from 'components/views/Dashboard/DashboardCharts/DashboardCharts';
import { FeedbacksFiltersShape } from 'components/views/Feedbacks/FeedbacksList/FeedbacksFilters';
import { FeedbackChartFiltersFormShape } from 'components/views/Feedbacks/FeedbacksSummary/FeedbacksSummaryFilters';
import { HedgesFiltersShape } from 'components/views/Hedges/HedgesList/HedgesFilters';
import { NonstandardConvertersFiltersShape } from 'components/views/NonstandardConverters/NonstandardConvertersList/NonstandardConvertersList';
import { StatisticFiltersFormShape } from 'components/views/Statistics/ConvertersStatistics/StatisticsPageFilters';
import { UsersListFiltersShape } from 'components/views/Users/UsersList/UsersFilters';
import { VehicleFiltersShape } from 'components/views/Vehicles/VehiclesList/VehicleListFilters';

export enum FilterableModules {
  ASSAYS = 'assays',
  COMPANIES = 'companies',
  CONVERTERS = 'converters',
  USERS = 'users',
  DASHBOARD = 'dashboard',
  HEDGES = 'hedges',
  VEHICLES = 'vehicles',
  STATISTICS = 'statistics',
  NONSTANDARD_CONVERTERS = 'nonstandard_converters',
  FEEDBACK_CHART = 'feedback_chart',
  FEEDBACKS = 'feedbacks',
}

interface Filters {
  [FilterableModules.ASSAYS]: AssayFiltersShape;
  [FilterableModules.VEHICLES]: VehicleFiltersShape;
  [FilterableModules.COMPANIES]: CompaniesFiltersShape;
  [FilterableModules.CONVERTERS]: ConvertersFiltersShape;
  [FilterableModules.USERS]: UsersListFiltersShape;
  [FilterableModules.DASHBOARD]: DashboardFiltersShape;
  [FilterableModules.HEDGES]: HedgesFiltersShape;
  [FilterableModules.STATISTICS]: StatisticFiltersFormShape;
  [FilterableModules.NONSTANDARD_CONVERTERS]: NonstandardConvertersFiltersShape;
  [FilterableModules.FEEDBACK_CHART]: FeedbackChartFiltersFormShape;
  [FilterableModules.FEEDBACKS]: FeedbacksFiltersShape;
}

export const DEFAULT_FILTERS: Filters = {
  [FilterableModules.ASSAYS]: {
    sampleDate: { from: null, to: null },
    folder: null,
    type: null,
    picture: '',
    removed: 'false',
  },
  [FilterableModules.VEHICLES]: {
    makeId: null,
    modelId: null,
    engineType: null,
    year: null,
    numberOfConverters: null,
    engineDisplacement: {
      from: null,
      to: null,
    },
  },
  [FilterableModules.COMPANIES]: {
    type: null,
    country: null,
    state: null,
    status: null,
  },
  [FilterableModules.CONVERTERS]: {
    makeId: null,
    modelId: null,
    converterType: null,
    years: { from: null, to: null },
    addedAt: { from: null, to: null },
    picture: null,
    showComingSoon: false,
    showCounterfeit: false,
  },
  [FilterableModules.USERS]: {
    status: null,
    ptPriceSource: null,
    pdPriceSource: null,
    rhPriceSource: null,
    company: null,
    accessFlags: [],
    userTypes: [],
  },
  [FilterableModules.DASHBOARD]: {
    from: 'Weeks-1',
  },
  [FilterableModules.HEDGES]: {
    status: null,
    users: [],
    placedOn: null,
    company: null,
  },
  [FilterableModules.STATISTICS]: {
    companies: [],
    users: [],
    date: 'Months-1',
  },
  [FilterableModules.NONSTANDARD_CONVERTERS]: {},
  [FilterableModules.FEEDBACK_CHART]: {
    company: null,
    thresholdInDays: null,
  },
  [FilterableModules.FEEDBACKS]: {
    status: null,
    type: null,
    company: null,
    dates: { from: null, to: null },
    rating: null,
  },
};

interface KeysSkipForDiff {
  [FilterableModules.ASSAYS]: (keyof AssayFiltersShape)[];
  [FilterableModules.VEHICLES]: (keyof VehicleFiltersShape)[];
  [FilterableModules.COMPANIES]: (keyof CompaniesFiltersShape)[];
  [FilterableModules.CONVERTERS]: (keyof ConvertersFiltersShape)[];
  [FilterableModules.USERS]: (keyof UsersListFiltersShape)[];
  [FilterableModules.DASHBOARD]: (keyof DashboardFiltersShape)[];
  [FilterableModules.HEDGES]: (keyof HedgesFiltersShape)[];
  [FilterableModules.STATISTICS]: (keyof StatisticFiltersFormShape)[];
  [FilterableModules.NONSTANDARD_CONVERTERS]: (keyof ConvertersFiltersShape)[];
  [FilterableModules.FEEDBACK_CHART]: (keyof FeedbackChartFiltersFormShape)[];
  [FilterableModules.FEEDBACKS]: (keyof FeedbacksFiltersShape)[];
}

export const KEYS_SKIP_FOR_DIFF: KeysSkipForDiff = {
  [FilterableModules.ASSAYS]: ['query'],
  [FilterableModules.VEHICLES]: [],
  [FilterableModules.COMPANIES]: ['query'],
  [FilterableModules.CONVERTERS]: ['query', 'searchSession'],
  [FilterableModules.USERS]: ['query'],
  [FilterableModules.DASHBOARD]: [],
  [FilterableModules.HEDGES]: [],
  [FilterableModules.STATISTICS]: [],
  [FilterableModules.NONSTANDARD_CONVERTERS]: ['query'],
  [FilterableModules.FEEDBACK_CHART]: [],
  [FilterableModules.FEEDBACKS]: ['query'],
};
