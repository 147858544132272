import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';

import { NoConnection } from 'components/views';
import { PERMISSIONS } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import {
  useCurrentUser,
  useExtendedTheme,
  useInitUserFilters,
  useNotificationCount,
} from 'shared/hooks';
import { withClearCache } from './shared/ClearCache';
import SEO from './shared/SEO/SEO';
import { routes } from './RootRoute';
import '../assets/fonts/fonts.css';

const router = createBrowserRouter(routes);

function MainApp() {
  const authorize = useAuthorization();
  const theme = useExtendedTheme();
  const user = useCurrentUser();

  useNotificationCount(
    user && authorize(PERMISSIONS.NOTIFICATIONS.MAIN) && user.company.grading !== null,
  );
  useInitUserFilters();

  if (!navigator.onLine) return <NoConnection />;

  return (
    <>
      <SEO />
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </>
  );
}

const ClearCacheElement = withClearCache(MainApp);

function App(): React.ReactElement {
  return <ClearCacheElement />;
}

export default App;
