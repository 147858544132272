import React from 'react';
import { DataOutput, OutputCell } from '@alpha-recycling/component-library';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { SHARED } from 'shared/constants';
import { Terms } from 'shared/types';
import { messages, useTypedIntl } from '../../locale/messages';

const percentageOrNull = (val?: number) => (val ? `${val.toFixed(2)} %` : SHARED.LONG_DASH);

type Props = {
  terms: Terms;
};

const RatesAndChargesComponent = ({ terms }: Props): React.ReactElement => {
  const intl = useTypedIntl();

  return (
    <DataOutput
      headerText={intl.formatMessage({
        id: 'Terms.RatesAndCharges.RatesAndCharges',
      })}
      dataTestId="rates-and-terms-section"
    >
      {[
        {
          name: 'platinumReturnRate',
          label: intl.formatMessage({ id: 'Terms.RatesAndCharges.PlatinumReturnRate' }),
          value: percentageOrNull(terms.platinumReturnRate),
        },
        {
          name: 'palladiumReturnRate',
          label: intl.formatMessage({ id: 'Terms.RatesAndCharges.PalladiumReturnRate' }),
          value: percentageOrNull(terms.palladiumReturnRate),
        },
        {
          name: 'rhodiumReturnRate',
          label: intl.formatMessage({ id: 'Terms.RatesAndCharges.RhodiumReturnRate' }),
          value: percentageOrNull(terms.rhodiumReturnRate),
        },
        {
          name: 'platinumReturnFinanceRate',
          label: intl.formatMessage({ id: 'Terms.RatesAndCharges.PlatinumReturnFinanceRate' }),
          value: percentageOrNull(terms.platinumReturnFinanceRate),
        },
        {
          name: 'palladiumReturnFinanceRate',
          label: intl.formatMessage({ id: 'Terms.RatesAndCharges.PalladiumReturnFinanceRate' }),
          value: percentageOrNull(terms.palladiumReturnFinanceRate),
        },
        {
          name: 'rhodiumReturnFinanceRate',
          label: intl.formatMessage({ id: 'Terms.RatesAndCharges.RhodiumReturnFinanceRate' }),
          value: percentageOrNull(terms.rhodiumReturnFinanceRate),
        },
        {
          name: 'platinumRefiningCharge',
          label: intl.formatMessage({ id: 'Terms.RatesAndCharges.PlatinumRefiningCharge' }),
          value: formatCurrency(terms.platinumRefiningCharge, 'USD'),
        },
        {
          name: 'palladiumRefiningCharge',
          label: intl.formatMessage({ id: 'Terms.RatesAndCharges.PalladiumRefiningCharge' }),
          value: formatCurrency(terms.palladiumRefiningCharge, 'USD'),
        },
        {
          name: 'rhodiumRefiningCharge',
          label: intl.formatMessage({ id: 'Terms.RatesAndCharges.RhodiumRefiningCharge' }),
          value: formatCurrency(terms.rhodiumRefiningCharge, 'USD'),
        },
      ].map((field, index, fields) => (
        <OutputCell
          key={field.name}
          dataTestId={field.name}
          mergeCells={fields.length - 1 === index ? 4 : 2}
          labelValue={field.label}
        >
          {field.value}
        </OutputCell>
      ))}
    </DataOutput>
  );
};

export const RatesAndCharges = withAlphamartIntlProvider(RatesAndChargesComponent, messages);
