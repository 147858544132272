export enum ErrorCode {
  ASSAY_NAME_ALREADY_EXIST = 'ASSAY_NAME_ALREADY_EXIST',
  HEDGE_IS_ALREADY_ASSIGNED = 'HEDGE_IS_ALREADY_ASSIGNED',
  MAXIMAL_REQUESTS_NUMBER_REACHED = 'MAXIMAL_REQUESTS_NUMBER_REACHED',
  UNRECOGNIZED_DEVICE = 'UNRECOGNIZED_DEVICE',
  VEHICLE_DATA_MIXED = 'VEHICLE_DATA_MIXED',
  VEHICLE_ALREADY_EXIST = 'VEHICLE_ALREADY_EXIST',
  VIN_NOT_UNIQUE = 'VEHICLE_WITH_THIS_VIN_ALREADY_EXISTS',
  NONSTANDARD_CONVERTER_MATERIAL_NOT_UNIQUE = 'NONSTANDARD_CONVERTER_MATERIAL_NOT_UNIQUE',
  NONSTANDARD_CONVERTER_INVALID_IDS_IN_RANK = 'NONSTANDARD_CONVERTER_INVALID_IDS_IN_RANK',
  NONSTANDARD_CONVERTER_IS_ASSIGNED_TO_VEHICLE = 'NONSTANDARD_CONVERTER_IS_ASSIGNED_TO_VEHICLE',
  LOGIN_FAILED = 'LOGIN_FAILED',
  UNAUTH_USER = 'UNAUTH_USER',
  UNSYNC_USER = 'UNSYNC_USER',
  CONVERTER_TOOL_ACCESS_FORBIDDEN = 'CONVERTER_TOOL_ACCESS_FORBIDDEN',
}
