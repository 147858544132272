import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '@alpha-recycling/component-library';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Row,
  SortingState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import _, { isUndefined } from 'lodash';

import { AlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { ContextMenu } from 'components/shared/ContextMenu';
import { ItemThumbnail, useGetRowId } from 'components/shared/List';
import { DataList } from 'components/shared/List/DataList';
import { LoadableContent, LoadableContentModes } from 'components/shared/Loader';
import { PanelContent } from 'components/shared/PanelContent/PanelContent';
import { PanelContentMain } from 'components/shared/PanelContentMain/PanelContentMain';
import { PanelContentSidebar } from 'components/shared/PanelContentSidebar/PanelContentSidebar';
import { PanelHeader } from 'components/shared/PanelHeader/PanelHeader';
import { PanelLayout } from 'components/shared/PanelLayout/PanelLayout';
import { Price } from 'components/shared/Price/Price';
import { SearchBar } from 'components/shared/SearchBar/SearchBar';
import {
  FilterableModules,
  Language,
  ModalFormType,
  PERMISSIONS,
  SUPPORTED_PORTAL_CURRENCIES,
  UNITS,
} from 'shared/constants';
import {
  SortableModules,
  SortDirection,
  SortSelectOptions,
  SortTypes,
} from 'shared/constants/sortableModules';
import { useAuthorization } from 'shared/helpers';
import { useAlphamartNavigate, useCurrentUser, useMediaQuery } from 'shared/hooks';
import { useGetVehicle, useGetVehicles } from 'shared/queries';
import { ConverterListItem, ItemAction } from 'shared/types';
import { diffObjects } from 'shared/utils/diffObjects';
import { changePageIndexAction, changePagination, setSortState } from 'store/listComponentSlice';
import { showModalForm } from 'store/modalFormSlice';
import { saveFilters } from 'store/shared/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { MEDIA_QUERY } from 'theme';
import {
  ConverterToRender,
  getVehiclesListWithUniqueConverters,
  NonstandardConverterToRender,
  VehicleListItemToRender,
} from './VehicleList.helpers';
import {
  NameColumn,
  panelContentStyles,
  PriceColumn,
  StyledIconTooltipWarning,
} from './VehicleList.styles';
import { VehicleFiltersShape, VehicleListFilters } from './VehicleListFilters';
import {
  messages,
  TypedFormattedMessage,
  useTypedIntl,
  VehiclesMessages,
} from '../locale/messages';
import { VehicleDetails } from '../VehicleDetails/VehicleDetails';
import { VehicleSearchModal } from '../VehicleSearch/VehicleSearchModal';

export const StyledPrice = ({
  currency,
  hasIncompletePrice,
  language,
  value,
}: {
  currency: SUPPORTED_PORTAL_CURRENCIES;
  hasIncompletePrice?: boolean;
  language: Language;
  value: number | null | undefined;
}): React.ReactElement => {
  const intl = useTypedIntl();
  return (
    <PriceColumn>
      {hasIncompletePrice && (
        <StyledIconTooltipWarning
          tooltip={intl.formatMessage({ id: 'VehiclesList.IncompletePrice' })}
        />
      )}
      <Price value={value} language={language} currency={currency} />
    </PriceColumn>
  );
};

const NestedConvertersList = ({ row: converterRow }: { row: Row<ConverterToRender> }) => {
  const intl = useTypedIntl();
  const isLg = useMediaQuery(MEDIA_QUERY.LG);
  const currentUser = useCurrentUser();
  const columns: ColumnDef<Partial<ConverterListItem>>[] = [
    {
      id: 'image',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.Image' }),
      cell: ({ row }) =>
        ItemThumbnail({
          photo: row.original.converterPhoto,
          attrAlt: row.original.converterIdentifier!,
        }),
    },
    {
      id: 'converterIdentifier',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.Identifier' }),
      cell: ({ row }) =>
        row.original.isPartial
          ? intl.formatMessage(
              { id: 'VehiclesList.PartialIdentifier' },
              { identifier: row.original.converterIdentifier },
            )
          : row.original.converterIdentifier,
    },
    {
      id: 'price',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.Price' }),
      cell: ({ row }) =>
        StyledPrice({
          value: row.original.price!.marketPrice,
          currency: currentUser.currency!,
          language: currentUser.language!,
        }),
      meta: { align: 'center' },
    },
  ];

  const table = useReactTable({
    columns,
    data: converterRow.original.converters,
    getRowId: useGetRowId(),
    getCoreRowModel: getCoreRowModel(),
    state: { columnVisibility: { image: isLg } },
    enableRowSelection: false,
  });

  const { rows } = table.getRowModel();

  return (
    <Table
      header={
        <TableHeader>
          {table.getHeaderGroups()[0].headers.map(({ id, column, getContext }) => (
            <TableCell key={id}>
              {`${flexRender(column.columnDef.header, getContext()) ?? ''}`}
            </TableCell>
          ))}
        </TableHeader>
      }
      restIndex={1}
      size="m"
    >
      {rows.map(row => (
        <TableRow key={row.id}>
          {row.getVisibleCells().map(cell => (
            <TableCell key={cell.id}>
              {flexRender(cell.column.columnDef.cell, cell.getContext() ?? {}) as ReactElement}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Table>
  );
};

const NestedVehicleConvertersList = ({
  row: vehicleRow,
}: {
  row: Row<VehicleListItemToRender>;
}) => {
  const intl = useTypedIntl();
  const currentUser = useCurrentUser();

  const columns: ColumnDef<ConverterToRender>[] = [
    {
      id: 'identifier',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.Identifier' }),
      cell: ({ row }) => row.original.identifier,
    },
    {
      id: 'averagePrice',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.AveragePrice' }),
      cell: ({ row }) =>
        StyledPrice({
          value: row.original.averagePrice,
          currency: currentUser.currency!,
          language: currentUser.language!,
          hasIncompletePrice: row.original.hasIncompletePrice,
        }),
    },
  ];

  const nonstandardColumns: ColumnDef<NonstandardConverterToRender>[] = [
    {
      id: 'identifier',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.Identifier' }),
      cell: ({ row }) => row.original.material,
    },
    {
      id: 'averagePrice',
      header: intl.formatMessage({ id: 'VehiclesList.SubTableHeader.AveragePrice' }),
      cell: ({ row }) =>
        StyledPrice({
          value: row.original.price,
          currency: currentUser.currency!,
          language: currentUser.language!,
          hasIncompletePrice: !row.original.price,
        }),
    },
  ];

  const table = useReactTable({
    columns,
    data: vehicleRow.original.vehicleConverters,
    getRowId: useGetRowId(),
    getRowCanExpand: row => !!row.original.converters?.length,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: false,
  });

  const nonstandardTable = useReactTable({
    columns: nonstandardColumns,
    data: vehicleRow.original.nonstandardVehicleConverters!,
    getRowId: useGetRowId(),
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: false,
  });

  const nonstandardConvertersExist = !!vehicleRow.original.nonstandardVehicleConverters?.length;

  const { rows } = table.getRowModel();
  const { rows: nonstandardRows } = nonstandardTable.getRowModel();

  return (
    <>
      {vehicleRow.original.vehicleConverters.length > 0 && (
        <Table
          header={
            <TableHeader>
              {table.getHeaderGroups()[0].headers.map(({ id, column, getContext }) => (
                <TableCell key={id}>
                  {`${flexRender(column.columnDef.header, getContext()) ?? ''}`}
                </TableCell>
              ))}
            </TableHeader>
          }
          subTableControlRow={table.getCanSomeRowsExpand()}
          restIndex={0}
          size="m"
        >
          {rows.map(row => (
            <TableRow
              key={row.id}
              {...(table.getCanSomeRowsExpand() && {
                subTables: [<NestedConvertersList row={row} />],
                subTableControlState: {
                  tableVisible: false,
                  hidden: row.original.converters.length === 0,
                },
              })}
            >
              {row.getVisibleCells().map(cell => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext() ?? {}) as ReactElement}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </Table>
      )}

      {nonstandardConvertersExist && (
        <Table
          header={
            <TableHeader>
              {nonstandardTable.getHeaderGroups()[0].headers.map(({ id, column, getContext }) => (
                <TableCell key={id}>
                  {`${flexRender(column.columnDef.header, getContext()) ?? ''}`}
                </TableCell>
              ))}
            </TableHeader>
          }
          restIndex={0}
          size="m"
        >
          {nonstandardRows.map(row => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map(cell => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext() ?? {}) as ReactElement}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </Table>
      )}
    </>
  );
};

const VehiclesListBase = (): React.ReactElement => {
  const navigate = useAlphamartNavigate();
  const authorize = useAuthorization();
  const dispatch = useAppDispatch();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const intl = useTypedIntl();
  const listComponent = useAppSelector(state => state.listComponent);
  const isLg = useMediaQuery(MEDIA_QUERY.LG);
  const isMd = useMediaQuery(MEDIA_QUERY.MD);
  const currentUser = useCurrentUser();
  const selectedSorting = listComponent.sortState?.[SortableModules.VEHICLES];
  const savedFilters = useAppSelector(state => state.filters.vehicles);
  const { data: vehicles, isFetching: isLoadingVehicles } = useGetVehicles(
    savedFilters.data,
    listComponent.pageIndex + 1,
    listComponent.pageSize,
    selectedSorting,
    { initialData: { data: [], count: 0 } },
  );

  const { id } = useParams<{ id: string }>();
  const { isFetching, data: vehicleData } = useGetVehicle(id!, { enabled: !isUndefined(id) });

  const vehiclesList = getVehiclesListWithUniqueConverters(vehicles?.data ?? []);
  const handleVinSearch = () =>
    dispatch(showModalForm({ modalType: ModalFormType.VinSearch, params: null }));

  const setPageIndex = currentPage => dispatch(changePageIndexAction(currentPage));

  const isPhone = useMediaQuery(MEDIA_QUERY.MAX_SM);
  const handleFiltersChanged = (changed: VehicleFiltersShape) => {
    const diffResult = diffObjects(savedFilters.data, changed);
    if (diffResult.count > 0) {
      setPageIndex(0);
    }
    dispatch(saveFilters({ filterKey: FilterableModules.VEHICLES, values: changed }));
  };

  const handleSortingChange = (changed: Updater<SortingState>) => {
    setPageIndex(0);
    dispatch(
      setSortState({
        moduleName: SortableModules.VEHICLES,
        value: changed.length
          ? {
              sortType: changed[0].id as SortTypes,
              sortDirection: changed[0].desc ? SortDirection.DESC : SortDirection.ASC,
            }
          : null,
      }),
    );
  };

  const createVehicle = () => {
    setFiltersOpen(false);
    navigate('/vehicles/create');
  };

  const handleVehicleView = item => {
    setFiltersOpen(false);
    navigate(`/vehicles/list/${item.id}`);
  };

  const handleUpdateView = item => {
    setFiltersOpen(false);
    navigate(`/vehicles/list/update/${item.id}`);
  };

  const actions: ItemAction<VehicleListItemToRender>[] = authorize(
    PERMISSIONS.VEHICLES.FULL_DETAILS,
  )
    ? [
        {
          label: <TypedFormattedMessage id="VehiclesList.Details.View" />,
          onClick: item => handleVehicleView(item),
          dataCy: item => `view-vehicle-${item?.id}`,
          visible: true,
        },
        {
          label: <TypedFormattedMessage id="Global.Update" />,
          onClick: item => handleUpdateView(item),
          dataCy: item => `edit-vehicle-${item?.id}`,
          visible: () => authorize(PERMISSIONS.VEHICLES.EDIT),
        },
      ]
    : [
        {
          label: <TypedFormattedMessage id="VehiclesList.Details.View" />,
          onClick: item => handleVehicleView(item),
          dataCy: item => `view-vehicle-${item?.id}`,
          visible: true,
        },
      ];

  const handleFiltersToggle = () => setFiltersOpen(!filtersOpen);

  const sortTypes: SortSelectOptions[] = [
    {
      label: intl.formatMessage({ id: `VehiclesList.Filters.UpdatedAt.ASC` }),
      value: { sortDirection: SortDirection.ASC, sortType: SortTypes.UPDATED_AT },
    },
    {
      label: intl.formatMessage({ id: `VehiclesList.Filters.UpdatedAt.DESC` }),
      value: { sortDirection: SortDirection.DESC, sortType: SortTypes.UPDATED_AT },
    },
  ];

  const getVehicleName = (row: Row<VehicleListItemToRender>) =>
    _(row.original).pick(['year', 'make', 'model']).values().filter(Boolean).join(' ');

  const columns: ColumnDef<VehicleListItemToRender>[] = [
    {
      id: 'image',
      header: intl.formatMessage({ id: 'VehiclesList.TableHeader.Image' }),
      cell: ({ row }) =>
        ItemThumbnail({ photo: row.original.vehiclePhoto, attrAlt: getVehicleName(row) }),
    },
    {
      id: 'makeModelYear',
      header: intl.formatMessage({ id: 'VehiclesList.TableHeader.MakeModelYear' }),
      cell: ({ row }) => <NameColumn>{getVehicleName(row)}</NameColumn>,
    },
    {
      id: 'engine',
      header: intl.formatMessage({ id: 'VehiclesList.TableHeader.Engine' }),
      cell: ({ row }) =>
        `${intl.formatMessage({
          id: `VehiclesList.EngineType.${row.original.engineType}` as keyof VehiclesMessages,
        })} ${row.original.engineDisplacement} ${UNITS.CC}`,
    },
    {
      id: 'numberOfConverters',
      header: intl.formatMessage({ id: 'VehiclesList.TableHeader.NumberOfConverters' }),
      cell: ({ row }) => row.original.numberOfConverters,
      meta: {
        align: 'center',
      },
    },
    {
      id: 'value',
      header: intl.formatMessage({ id: 'VehiclesList.TableHeader.Value' }),
      cell: ({ row }) => (
        <StyledPrice
          value={row.original.avgConvertersPrice}
          hasIncompletePrice={row.original.hasIncompletePrice}
          currency={currentUser.currency!}
          language={currentUser.language!}
        />
      ),
    },
    {
      id: 'actions',
      header: '',
      cell: ({ row }) => (
        <ContextMenu
          offsetX={-60}
          trigger={
            <Button data-cy="actionButton" iconName="ThreeDots" variant="plain" content="icon" />
          }
        >
          {actions.map(
            action =>
              (typeof action.visible === 'function'
                ? (action.visible as (item?: VehicleListItemToRender | undefined) => boolean)(
                    row.original,
                  )
                : action.visible) && (
                <button
                  type="button"
                  onClick={() => action.onClick(row.original)}
                  key={action.id}
                  data-cy={action.dataCy?.(row.original)}
                >
                  {action.label}
                </button>
              ),
          )}
        </ContextMenu>
      ),
    },
  ];

  const table = useReactTable({
    columns,
    data: vehiclesList,
    getRowId: useGetRowId(),
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: false,
    getRowCanExpand: row =>
      (row.original.vehicleConverters?.length > 0 ||
        (!!row.original.nonstandardVehicleConverters &&
          row.original.nonstandardVehicleConverters.length > 0)) &&
      authorize(PERMISSIONS.VEHICLES.FULL_DETAILS),
    pageCount: Math.ceil((vehicles?.count ?? 0) / listComponent.pageSize),
    onPaginationChange: pagination => dispatch(changePagination(pagination)),
    onSortingChange: handleSortingChange,
    manualPagination: true,
    manualSorting: true,
    state: {
      columnVisibility: {
        engine: isLg,
        numberOfConverters: isMd,
      },
      pagination: { pageIndex: listComponent.pageIndex, pageSize: listComponent.pageSize },
      sorting: selectedSorting
        ? [
            {
              id: selectedSorting.sortType,
              desc: selectedSorting.sortDirection === SortDirection.DESC,
            },
          ]
        : [],
    },
  });

  return (
    <LoadableContent loading={isFetching} mode={LoadableContentModes.FULL} drawContent>
      <PanelLayout>
        <PanelHeader title={<TypedFormattedMessage id="VehiclesList.Header" />}>
          {authorize(PERMISSIONS.VEHICLES.CREATE) ? (
            <Button
              dataTestId="create-vehicle-button"
              onClick={createVehicle}
              iconName="Plus"
              variant="filled"
              label={intl.formatMessage({ id: 'Global.Create' })}
            />
          ) : null}
          <Button
            dataTestId="vin-search-button"
            onClick={handleVinSearch}
            content="text"
            variant="filled"
            label={intl.formatMessage({
              id: `VehiclesMain.${isPhone ? 'SearchByVin.Short' : 'SearchByVin'}`,
            })}
          />
        </PanelHeader>
        <PanelContent>
          <PanelContentMain className={panelContentStyles}>
            <SearchBar
              searchInputVisible={false}
              onFiltersToggle={handleFiltersToggle}
              savedFilters={savedFilters}
            />
            <DataList
              table={table}
              isLoading={isLoadingVehicles}
              subTables={[NestedVehicleConvertersList]}
              sortOptions={sortTypes}
              restIndex={1}
            />
          </PanelContentMain>
          <PanelContentSidebar
            header={intl.formatMessage({ id: 'Global.Filters' })}
            headerIcon="Filter"
            open={filtersOpen}
            onSidebarClosed={handleFiltersToggle}
          >
            <VehicleListFilters
              onFiltersChanged={handleFiltersChanged}
              onFiltersApplied={handleFiltersToggle}
            />
          </PanelContentSidebar>
        </PanelContent>
        <VehicleDetails vehicleDetails={vehicleData!} />
      </PanelLayout>
    </LoadableContent>
  );
};

export const VehiclesList = (): React.ReactElement => (
  <AlphamartIntlProvider messages={messages}>
    <VehiclesListBase />
    <VehicleSearchModal />
  </AlphamartIntlProvider>
);
