import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ButtonProps, Form, FormRow, OptionsProps } from '@alpha-recycling/component-library';
import { useFormik } from 'formik';
import { capitalize, isNil, pick } from 'lodash';

import { FieldInput } from 'components/shared/Fields/FieldInput/FieldInput';
import { FieldPhotoRaw } from 'components/shared/Fields/FieldPhotos/FieldPhotoRaw';
import {
  FieldPhotos,
  MAX_PHOTOS_COUNT,
  PhotoShapeUnion,
} from 'components/shared/Fields/FieldPhotos/FieldPhotos';
import { FieldCreatableSelect } from 'components/shared/Fields/FieldSelect/FieldCreatableSelect';
import { FieldSelect } from 'components/shared/Fields/FieldSelect/FieldSelect';
import { FieldTextArea } from 'components/shared/Fields/FieldTextArea/FieldTextArea';
import { FormInWizard } from 'components/shared/forms/Form/FormInWizard';
import { WizardContext } from 'components/shared/forms/Wizard/Wizard';
import { LoadableContent } from 'components/shared/Loader';
import { ENGINE_TYPES, SHARED, UNITS } from 'shared/constants';
import { TRANSMISSION_TYPES } from 'shared/constants/transmissionTypes';
import { VEHICLE_TYPES } from 'shared/constants/vehicleTypes';
import { useVinImageRecognition } from 'shared/mutations/vinImageRecognition';
import { VehicleFileSource } from 'shared/parsers/parseVehicle';
import { useDecodeVin, useGetModels } from 'shared/queries';
import { useGetExternalPhoto } from 'shared/queries/externalPhoto';
import { Vehicle } from 'shared/types';
import { useAppDispatch, useAppSelector } from 'store/shared/hooks';
import { snackBarPushFailure } from 'store/shared/snackBarSlice';
import { shouldShowDecodeVinError } from './VehicleForm.helpers';
import { useTypedIntl, VehiclesMessages } from '../locale/messages';

export interface VehicleFormShape extends Vehicle {
  weightConfirmation: string | null;
  engineDisplacementConfirmation: string | null;
  enginePowerConfirmation: string | null;
  converters: {
    key: string;
    identifier: string;
    identifierConfirm: string;
  }[];
  nonstandardConverters: {
    key: string;
    nonstandardConverterId: number;
    materialWeight?: number | null;
  }[];
  engineDisplacement: string;
  enginePower: string;
  engineType: string;
  make: string;
  model: string;
  notes: string;
  numberOfDoors: number;
  photos: PhotoShapeUnion[];
  transmission: string | null;
  vehicleType: string;
  vin: string;
  weight: string;
  year: number;
}

interface Props
  extends Pick<
    ReturnType<typeof useFormik<VehicleFormShape>>,
    | 'setFieldTouched'
    | 'values'
    | 'setFieldError'
    | 'setFieldValue'
    | 'touched'
    | 'handleChange'
    | 'handleBlur'
  > {
  context: ReturnType<typeof useFormik<VehicleFormShape>>;
  getErrors: (fieldName: string) => string | false | undefined;
  activeInput: string | null;
  setActiveInput: (input: string | null) => void;
  editMode?: boolean;
  controlButtons?: ReactNode;
}

const VehicleFormStepOne = ({
  handleChange,
  handleBlur,
  values,
  getErrors,
  context,
  activeInput,
  setActiveInput,
  setFieldError,
  setFieldValue,
  setFieldTouched,
  touched,
  editMode = false,
  controlButtons,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const currentYear = new Date().getFullYear();
  const startingYear = SHARED.VEHICLES_STARTING_YEAR;
  const yearsArray = currentYear - startingYear + 1;
  const yearArray = [...Array(yearsArray).keys()].map(i => ({
    value: currentYear - i,
    label: (currentYear - i).toString(),
  }));
  const [yearOptions, setYearOptions] = useState(yearArray);
  const numberOfDoorsOptions: OptionsProps[] = [];
  const dispatch = useAppDispatch();
  const { imageExtensions, fileSizeLimit } = useAppSelector(
    state => state.config.upload!.converterPhoto,
  );
  const imageRecognitionConfig = useAppSelector(state => state.config.upload!.imageRecognition);
  const vinImageRecognition = useVinImageRecognition();
  const [vinToDecode, setVinToDecode] = useState<string>('');
  const { makes } = useAppSelector(state => state.makes);
  const models = useGetModels();

  const makesOptions = makes?.map(make => ({ label: make.name, value: make.name }));
  const modelsOptions = models.data!.map(model => ({
    label: model.name,
    value: model.name,
  }));
  const restoreCratedMake = !isNil(values.make) && !makes.find(el => el.name === values.make);
  const restoreCreatedModel =
    !isNil(values.model) && !models.data!.find(el => el.name === values.model);
  if (restoreCratedMake) makesOptions.push({ label: values.make, value: values.make });
  if (restoreCreatedModel) modelsOptions.push({ label: values.model, value: values.model });

  const vehicleTypeOptions = Object.values(VEHICLE_TYPES).map(vehicle => ({
    label: intl.formatMessage({
      id: `VehicleForm.VehicleType.${capitalize(
        vehicle.replace('(', '').replace(')', '').replace(/\s/g, '_'),
      )}` as keyof VehiclesMessages,
    }),
    value: vehicle,
  }));

  const engineTypeOptions = Object.values(ENGINE_TYPES).map(engine => ({
    label: intl.formatMessage({
      id: `VehicleForm.EngineType.${capitalize(
        engine.replace(/\s/g, ''),
      )}` as keyof VehiclesMessages,
    }),
    value: engine,
  }));

  const transmissionOptions = Object.values(TRANSMISSION_TYPES).map(transmission => ({
    label: intl.formatMessage({
      id: `VehicleForm.TransmissionType.${capitalize(
        transmission.replace(/\s/g, ''),
      )}` as keyof VehiclesMessages,
    }),
    value: transmission,
  }));

  for (let i = 2; i <= 6; i++) {
    numberOfDoorsOptions.push({
      value: i,
      label: i.toString(),
    });
  }

  const handleMakeChange = useCallback(async value => setFieldValue('make', value?.value), []);
  const handleModelChange = useCallback(async value => setFieldValue('model', value?.value), []);

  const onBlur = e => {
    setActiveInput('');
    handleBlur(e);
  };

  const decodeVin = useDecodeVin(vinToDecode);

  useEffect(() => {
    handleDecodeVin();
  }, [decodeVin.data, decodeVin.isError]);

  const handleVinBlur = e => {
    handleBlur(e);
    if (getErrors('vin')) return;

    setVinToDecode(values.vin);
  };

  const handleDecodeVin = () => {
    if (shouldShowDecodeVinError(decodeVin, vinToDecode)) {
      dispatch(snackBarPushFailure(intl.formatMessage({ id: 'VehicleForm.Errors.Vin.NotFound' })));
      return;
    }
    if (!decodeVin.data) {
      return;
    }
    const {
      make,
      model,
      year,
      engineType,
      engineDisplacement,
      enginePower,
      transmission,
      vehicleType,
      numberOfDoors,
    } = decodeVin.data;

    const isExistingYear = yearOptions.some(({ value }) => value === year);

    if (year && !isExistingYear)
      setYearOptions(currOptions => [
        ...currOptions,
        {
          value: year,
          label: year.toString(),
        },
      ]);

    setFieldValue('make', make?.toUpperCase() ?? undefined);
    setFieldValue('model', model?.toUpperCase() ?? undefined);
    setFieldValue('year', year ?? undefined);
    setFieldValue('engineType', engineType ?? undefined);
    setFieldValue('engineDisplacement', engineDisplacement ?? '');
    setFieldValue('engineDisplacementConfirmation', engineDisplacement ?? '');
    setFieldValue('enginePower', enginePower ?? '');
    setFieldValue('enginePowerConfirmation', enginePower ?? '');
    setFieldValue(
      'transmission',
      transmission
        ?.split('/')
        .find(option =>
          transmissionOptions.map(({ value }) => value).includes(option as TRANSMISSION_TYPES),
        ) ?? undefined,
    );
    setFieldValue(
      'vehicleType',
      vehicleType
        ?.split('/')
        .find(type =>
          vehicleTypeOptions.map(({ value }) => value).includes(type as VEHICLE_TYPES),
        ) ?? undefined,
    );
    setFieldValue('numberOfDoors', numberOfDoors ?? undefined);
  };

  const handleVinImageRecognition = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.[0]) return;

    try {
      const imageRecognitionResult = await vinImageRecognition.mutateAsync(event.target.files[0]);
      setVinToDecode(imageRecognitionResult);
      setFieldValue('vin', imageRecognitionResult);
    } catch (error) {
      dispatch(
        snackBarPushFailure(intl.formatMessage({ id: 'VehicleForm.Vin.ImageRecognition.Error' })),
      );
    }
  };

  const validateNewMake = val => {
    if (!touched?.make) setFieldTouched('make');

    if (!/^[0-9A-Z\- /.+]+$/.test(val))
      return setFieldError(
        'make',
        intl.formatMessage({
          id: 'VehicleForm.Errors.Make.NotMatchRegex',
        }),
      );

    setFieldError('make', undefined);
  };

  const validateNewModel = val => {
    if (!touched?.model) setFieldTouched('model');

    if (!/^[0-9A-Z\- /.+]+$/.test(val))
      return setFieldError(
        'model',
        intl.formatMessage({
          id: 'VehicleForm.Errors.Model.NotMatchRegex',
        }),
      );

    setFieldError('model', undefined);
  };

  const { contextPhotos, setContextPhotos } = useContext(WizardContext);

  const params = pick(values, ['make', 'model', 'year']);

  const canFetchPhoto = useMemo(
    () =>
      !!(
        !contextPhotos.some(photo => 'url' in photo && !('externalId' in photo)) &&
        !contextPhotos.some(photo => photo.source === VehicleFileSource.EVOX) &&
        params.make &&
        params.model &&
        params.year
      ),
    [contextPhotos, params.make, params.model, params.year],
  );

  const externalPhotoQuery = useGetExternalPhoto(params, {
    enabled: canFetchPhoto,
  });

  const onFetchPhoto = useCallback(async () => {
    const { data } = externalPhotoQuery;
    if (!canFetchPhoto || !data) return;
    const filename = new URL(data.url).pathname.split('/').at(-1);
    const newPhoto = {
      ...data,
      id: data.url,
      name: filename,
    };

    const newPhotosSet = [newPhoto, ...contextPhotos].slice(0, MAX_PHOTOS_COUNT);
    setContextPhotos(newPhotosSet);
    setFieldTouched('photos');
    await handleChange({
      target: {
        name: 'photos',
        value: newPhotosSet,
      },
    });
  }, [params, externalPhotoQuery.data, canFetchPhoto]);

  const handleProtectedBlur = e => {
    setFieldTouched(`${e.target.name}Confirmation`);
    return onBlur(e);
  };

  useEffect(() => {
    if (!values.weight) setFieldValue('weightConfirmation', '');
    if (!values.engineDisplacement) setFieldValue('engineDisplacementConfirmation', '');
    if (!values.enginePower) setFieldValue('enginePowerConfirmation', '');
  }, [values.weight, values.engineDisplacement, values.enginePower]);

  const isPending = decodeVin.isFetching || vinImageRecognition.isLoading;

  const externalPhotoAvailable = !!externalPhotoQuery.data?.url;

  return (
    <LoadableContent mode={LoadableContent.MODE.FULL} drawContent loading={isPending}>
      <FormInWizard
        context={context}
        header={intl.formatMessage(
          editMode ? { id: 'VehicleForm.UpdateVehicle' } : { id: 'VehicleForm.CreateVehicle' },
        )}
        controlButtons={controlButtons}
      >
        <Form
          headerText={intl.formatMessage({ id: 'VehicleForm.Section.Vin' })}
          headerButton={false as unknown as ButtonProps}
        >
          <FormRow>
            <FieldInput
              label={intl.formatMessage({ id: 'VehicleForm.Vin' })}
              name="vin"
              onChange={handleChange}
              onBlur={handleVinBlur}
              value={values.vin}
              error={getErrors('vin')}
              data-cy="vin"
              onFocus={e => setActiveInput(e.target.name)}
              capitalize
              maxLength={17}
            />
            <FieldPhotoRaw
              onChange={handleVinImageRecognition}
              allowedExtensions={imageRecognitionConfig.imageExtensions}
              fileSizeLimit={imageRecognitionConfig.fileSizeLimit}
              label={intl.formatMessage({ id: 'VehicleForm.Vin.ImageRecognition.Label' })}
              name="vin-image"
            />
          </FormRow>
        </Form>
        <Form
          headerText={intl.formatMessage({ id: 'VehicleForm.Section.VehicleDetails' })}
          headerButton={false as unknown as ButtonProps}
        >
          <FormRow>
            <FieldCreatableSelect
              label={intl.formatMessage({ id: 'VehicleForm.Make' })}
              name="make"
              options={makesOptions}
              onChange={handleMakeChange}
              value={values.make}
              error={getErrors('make')}
              validateInput={validateNewMake}
              data-cy="make"
              onFocus={e => setActiveInput(e.target.name)}
              required
              maxLength={100}
            />
            <FieldCreatableSelect
              label={intl.formatMessage({ id: 'VehicleForm.Model' })}
              name="model"
              options={modelsOptions}
              onChange={handleModelChange}
              value={values.model}
              error={getErrors('model')}
              validateInput={validateNewModel}
              data-cy="model"
              onFocus={e => setActiveInput(e.target.name)}
              required
              maxLength={100}
            />
            <FieldSelect
              label={intl.formatMessage({ id: 'VehicleForm.Year' })}
              name="year"
              options={yearOptions}
              value={values.year}
              data-cy="year"
              onFocus={e => setActiveInput(e.target.name)}
              error={getErrors('year')}
              onBlur={onBlur}
              required
            />
            <FieldSelect
              label={intl.formatMessage({ id: 'VehicleForm.VehicleType' })}
              name="vehicleType"
              options={vehicleTypeOptions}
              value={values.vehicleType}
              data-cy="vehicle-type"
              onFocus={e => setActiveInput(e.target.name)}
            />
            <FieldInput
              label={intl.formatMessage({ id: 'VehicleForm.Weight' })}
              name="weight"
              onChange={handleChange}
              onBlur={handleProtectedBlur}
              value={values.weight}
              error={getErrors('weight')}
              data-cy="weight"
              suffix={UNITS.LBS}
              onFocus={e => setActiveInput(e.target.name)}
              protection={{
                hide: activeInput === 'weightConfirmation',
                copy: true,
                paste: true,
              }}
              autoComplete="off"
              maxLength={6}
            />
            <FieldInput
              label={intl.formatMessage({ id: 'VehicleForm.WeightConfirmation' })}
              name="weightConfirmation"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.weightConfirmation ?? ''}
              error={getErrors('weightConfirmation')}
              data-cy="weight-confirmation"
              disabled={!values.weight}
              suffix={UNITS.LBS}
              onFocus={e => setActiveInput(e.target.name)}
              protection={{
                hide: activeInput === 'weight',
                copy: true,
                paste: true,
              }}
              autoComplete="off"
            />
            <FieldSelect
              label={intl.formatMessage({ id: 'VehicleForm.NumberOfDoors' })}
              name="numberOfDoors"
              options={numberOfDoorsOptions}
              value={values.numberOfDoors}
              data-cy="number-of-doors"
              onFocus={e => setActiveInput(e.target.name)}
            />
          </FormRow>
        </Form>
        <Form
          headerText={intl.formatMessage({ id: 'VehicleForm.Section.Photos' })}
          headerButton={false as unknown as ButtonProps}
        >
          <FormRow>
            <FieldPhotos
              name="photos"
              onChange={handleChange}
              onBlur={() => setFieldTouched('photos')}
              onTouch={setFieldTouched}
              error={getErrors('photos')}
              allowedExtensions={imageExtensions}
              initialPhotos={values.photos}
              data-cy="photos"
              fileSizeLimit={fileSizeLimit}
              canFetchPhoto={canFetchPhoto}
              isFetchingPhoto={externalPhotoQuery.isFetching}
              externalPhotoAvailable={externalPhotoAvailable}
              onFetchPhoto={onFetchPhoto}
            />
          </FormRow>
        </Form>
        <Form
          headerText={intl.formatMessage({ id: 'VehicleForm.Section.EngineDetails' })}
          headerButton={false as unknown as ButtonProps}
        >
          <FormRow>
            <FieldSelect
              label={intl.formatMessage({ id: 'VehicleForm.EngineType' })}
              name="engineType"
              options={engineTypeOptions}
              value={values.engineType}
              data-cy="engine-type"
              onFocus={e => setActiveInput(e.target.name)}
              error={getErrors('engineType')}
              onBlur={onBlur}
              required
            />
            <FieldSelect
              label={intl.formatMessage({ id: 'VehicleForm.Transmission' })}
              name="transmission"
              options={transmissionOptions}
              value={values.transmission ?? ''}
              data-cy="transmission"
              onFocus={e => setActiveInput(e.target.name)}
            />
            <FieldInput
              label={intl.formatMessage({ id: 'VehicleForm.EngineDisplacement' })}
              name="engineDisplacement"
              onChange={handleChange}
              onBlur={handleProtectedBlur}
              value={values.engineDisplacement}
              error={getErrors('engineDisplacement')}
              suffix={UNITS.CC}
              data-cy="engine-displacement"
              onFocus={e => setActiveInput(e.target.name)}
              required
              protection={{
                hide: activeInput === 'engineDisplacementConfirmation',
                copy: true,
                paste: true,
              }}
              autoComplete="off"
              maxLength={5}
            />
            <FieldInput
              label={intl.formatMessage({ id: 'VehicleForm.EngineDisplacementConfirmation' })}
              name="engineDisplacementConfirmation"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.engineDisplacementConfirmation ?? ''}
              error={getErrors('engineDisplacementConfirmation')}
              suffix={UNITS.CC}
              disabled={!values.engineDisplacement}
              data-cy="engine-displacement-confirmation"
              onFocus={e => setActiveInput(e.target.name)}
              required
              protection={{
                hide: activeInput === 'engineDisplacement',
                copy: true,
                paste: true,
              }}
              autoComplete="off"
            />
            <FieldInput
              label={intl.formatMessage({ id: 'VehicleForm.EnginePower' })}
              name="enginePower"
              onChange={handleChange}
              onBlur={handleProtectedBlur}
              value={values.enginePower}
              suffix={UNITS.HP}
              error={getErrors('enginePower')}
              data-cy="engine-power"
              onFocus={e => setActiveInput(e.target.name)}
              protection={{
                hide: activeInput === 'enginePowerConfirmation',
                copy: true,
                paste: true,
              }}
              autoComplete="off"
              maxLength={5}
            />
            <FieldInput
              label={intl.formatMessage({ id: 'VehicleForm.EnginePowerConfirmation' })}
              name="enginePowerConfirmation"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.enginePowerConfirmation ?? ''}
              suffix={UNITS.HP}
              disabled={!values.enginePower}
              error={getErrors('enginePowerConfirmation')}
              data-cy="engine-power-confirmation"
              onFocus={e => setActiveInput(e.target.name)}
              protection={{
                hide: activeInput === 'enginePower',
                copy: true,
                paste: true,
              }}
              autoComplete="off"
            />
          </FormRow>
        </Form>
        <Form
          headerText={intl.formatMessage({ id: 'VehicleForm.Note' })}
          headerButton={false as unknown as ButtonProps}
        >
          <FormRow>
            <FieldTextArea
              id="note"
              label={intl.formatMessage({ id: 'VehicleForm.Note' })}
              name="notes"
              onChange={handleChange}
              onBlur={onBlur}
              value={values.notes}
              data-cy="notes"
              onFocus={e => setActiveInput(e.target.name)}
              maxLength={300}
            />
          </FormRow>
        </Form>
      </FormInWizard>
    </LoadableContent>
  );
};

export { VehicleFormStepOne };
