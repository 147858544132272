import React from 'react';
import { DataOutput, OutputCell } from '@alpha-recycling/component-library';

import { withAlphamartIntlProvider } from 'components/shared/AlphamartIntlProvider';
import { formatCurrency } from 'helpers/formatCurrency/formatCurrency';
import { Terms } from 'shared/types';
import { messages, useTypedIntl } from '../../locale/messages';

type Props = {
  terms: Terms;
};

const OtherChargesComponent = ({ terms }: Props): React.ReactElement => {
  const intl = useTypedIntl();

  return (
    <DataOutput
      headerText={intl.formatMessage({
        id: 'Terms.OtherCharges.OtherCharges',
      })}
      dataTestId="other-charges-section"
    >
      {[
        {
          name: 'metalsReturnTermInDays',
          label: intl.formatMessage({ id: 'Terms.OtherCharges.MetalsReturnTermInDays' }),
          value: terms.metalsReturnTermInDays,
        },
        {
          name: 'treatmentChargePerPound',
          label: intl.formatMessage({ id: 'Terms.OtherCharges.TreatmentChargePerPound' }),
          value: formatCurrency(terms.treatmentChargePerPound, 'USD'),
        },
      ].map(field => (
        <OutputCell
          key={field.name}
          dataTestId={field.name}
          mergeCells={2}
          labelValue={field.label}
        >
          {field.value}
        </OutputCell>
      ))}
    </DataOutput>
  );
};

export const OtherCharges = withAlphamartIntlProvider(OtherChargesComponent, messages);
