import { AccessFlag } from './accessFlag';
import { UserTypes } from './userTypes';

export const ALLOW_ALL = '*' as const;

const PERMISSIONS = {
  DASHBOARD: {
    SHOW: ALLOW_ALL,
  },
  CONVERTERS: {
    LIST: [AccessFlag.CONVERTER_VIEWER],
    LIST_SAMPLE: [AccessFlag.ASSAY_VIEWER, UserTypes.MANAGER],
    FILTERS_ADVANCED: [AccessFlag.ASSAY_EDITOR],
    DETAILS: {
      CREATED_BY: [AccessFlag.ASSAY_EDITOR],
      ASSAY: [AccessFlag.ASSAY_VIEWER],
    },
    PARTIAL_CONVERTERS: [AccessFlag.PARTIAL_CONVERTER_MANAGER],
    DISCLAIMER: [UserTypes.MANAGER, UserTypes.REGULAR_USER],
  },
  USERS: {
    LIST: [AccessFlag.USER_MANAGER],
    LIST_COMPANY_NAME: [AccessFlag.COMPANY_MANAGER],
    FILTER_ALL_USER_TYPES: [AccessFlag.COMPANY_MANAGER],
    FILTER_BY_COMPANY: [AccessFlag.COMPANY_MANAGER],
    FILTER_BY_ACCESS_FLAGS: [UserTypes.SUPER_ADMIN],
    FILTER_HEDGES: [AccessFlag.HEDGE_BASIC_VIEWER],
    EXPORT_SHOPPING_CART_LOGS: [AccessFlag.REPORT_ADVANCED_GENERATOR],
    UPDATE: [AccessFlag.USER_MANAGER],
    DETAILS: {
      SHOW_USER_PRICES_FIELD: [AccessFlag.HEDGE_EDITOR],
      SHOW_USER_TERMS_ADJUSTMENTS_FIELD: [AccessFlag.COMPANY_MANAGER],
      SHOW_USER_DEVICES_DETAILS: [AccessFlag.USER_MANAGER],
    },
    UPDATE_TERMS_ADJUSTMENTS: [AccessFlag.COMPANY_MANAGER],
    KNOW_ABOUT_OTHER_COMPANY_USERS: [AccessFlag.COMPANY_MANAGER],
    UNBLOCK: [AccessFlag.COMPANY_MANAGER],
    SHOW_DISPLAY_HEDGE_PRICES_FIELD: [AccessFlag.HEDGE_PRICE_VIEWER],
    EDIT_USAGES_THRESHOLDS: [AccessFlag.STATISTICS_VIEWER],
    MANAGE_ACCESS: [UserTypes.SUPER_ADMIN],
  },
  COMPANIES: {
    LIST: [AccessFlag.COMPANY_MANAGER],
    VIEW_COMPANY_TYPE: [UserTypes.SUPER_ADMIN],
    LIST_RESTRICTED_FIELDS: [UserTypes.SUPER_ADMIN],
    UPDATE: [AccessFlag.COMPANY_MANAGER],
    DETAILS: {
      TYPE: [UserTypes.SUPER_ADMIN],
    },
    EDIT_USAGES_THRESHOLDS: [AccessFlag.STATISTICS_VIEWER],
    EXPORT_HEDGE_USE_LOGS: [AccessFlag.REPORT_BASIC_GENERATOR],
    EXPORT_CONVERTER_LOGS: [AccessFlag.REPORT_BASIC_GENERATOR],
  },
  ASSAYS: {
    LIST: [AccessFlag.ASSAY_VIEWER],
    FILTERS_REMOVED: [AccessFlag.ASSAY_EDITOR],
    CREATE: [AccessFlag.ASSAY_EDITOR],
    UPDATE: [AccessFlag.ASSAY_EDITOR],
  },
  COMPANY_SETTINGS: {
    MAIN: [AccessFlag.SETTINGS_MANAGER],
    UPDATE_PRICES: [UserTypes.SUPER_ADMIN],
  },
  COMPANY_DISPLAY_SETTINGS: [UserTypes.SUPER_ADMIN],
  USER_PROFILE: {
    CHANGE_PROFIT_MARGIN: [AccessFlag.CONVERTER_VIEWER],
    EDIT_USAGES_THRESHOLDS: [AccessFlag.STATISTICS_VIEWER],
    SHOW_PRICE_SOURCE: [AccessFlag.CONVERTER_VIEWER],
  },
  VEHICLES: {
    MAIN: [
      AccessFlag.VEHICLE_BASIC_VIEWER,
      AccessFlag.VEHICLE_ADVANCED_VIEWER,
      AccessFlag.VEHICLE_CREATOR,
      AccessFlag.VEHICLE_EDITOR,
    ],
    LIST: [AccessFlag.VEHICLE_BASIC_VIEWER, AccessFlag.VEHICLE_ADVANCED_VIEWER],
    FULL_DETAILS: [AccessFlag.VEHICLE_ADVANCED_VIEWER],
    CREATE: [AccessFlag.VEHICLE_CREATOR],
    EDIT: [AccessFlag.VEHICLE_EDITOR],
  },
  HEDGES: {
    LIST: [AccessFlag.HEDGE_BASIC_VIEWER],
    FILTER_BY_COMPANY: [
      UserTypes.SUPER_ADMIN,
      UserTypes.INTERNAL_COMPANY_MANAGER,
      UserTypes.CUSTOMER_SUPPORT,
    ],
    DETAILS: {
      SHOW_PAGE: [AccessFlag.HEDGE_BASIC_VIEWER],
      SHOW_COMPANY: [
        UserTypes.SUPER_ADMIN,
        UserTypes.INTERNAL_COMPANY_MANAGER,
        UserTypes.CUSTOMER_SUPPORT,
      ],
    },
    CREATE: [AccessFlag.HEDGE_EDITOR],
    CHANGE_COMPANY: [
      UserTypes.SUPER_ADMIN,
      UserTypes.INTERNAL_COMPANY_MANAGER,
      UserTypes.CUSTOMER_SUPPORT,
    ],
    LIST_RESTRICTED_FIELDS: [AccessFlag.HEDGE_EDITOR],
    UPDATE: [AccessFlag.HEDGE_EDITOR],
    REMOVE: [AccessFlag.HEDGE_EDITOR],
    UNASSIGN: [AccessFlag.HEDGE_EDITOR],
    ASSIGN: {
      ACTION: [AccessFlag.HEDGE_EDITOR],
      SHOW_FULL_HEDGE_DATA: [AccessFlag.HEDGE_EDITOR],
    },
    SHOW_HEDGE_PRICES: [AccessFlag.HEDGE_PRICE_VIEWER],
    SHOW_HEDGE_COMPANY: [
      UserTypes.SUPER_ADMIN,
      UserTypes.INTERNAL_COMPANY_MANAGER,
      UserTypes.CUSTOMER_SUPPORT,
    ],
  },
  NOTIFICATIONS: {
    MAIN: [AccessFlag.DASHBOARD_VIEWER],
    ALL_DETAILS: [AccessFlag.SETTINGS_MANAGER],
  },
  STATISTICS: {
    LIST: [AccessFlag.STATISTICS_VIEWER],
  },
  NONSTANDARD_CONVERTERS: {
    MAIN: [AccessFlag.NON_STANDARD_CONVERTER_EDITOR, AccessFlag.NON_STANDARD_CONVERTER_VIEWER],
    CREATE: [AccessFlag.NON_STANDARD_CONVERTER_EDITOR],
    UPDATE: [AccessFlag.NON_STANDARD_CONVERTER_EDITOR],
    SEARCH: [AccessFlag.NON_STANDARD_CONVERTER_EDITOR, AccessFlag.NON_STANDARD_CONVERTER_VIEWER],
  },
  FEEDBACKS: {
    MAIN: [AccessFlag.FEEDBACK_MANAGER],
  },
  SHOPPING_CART: {
    MAIN: [AccessFlag.SHOPPING_CART_VIEWER],
  },
  TERMS: {
    MAIN: [AccessFlag.TERMS_MANAGER],
  },
  SET_YOUR_APP: {
    QUESTIONNAIRE: [AccessFlag.TERMS_MANAGER],
    INTERNAL_SETUP: [
      UserTypes.SUPER_ADMIN,
      UserTypes.INTERNAL_COMPANY_MANAGER,
      UserTypes.CUSTOMER_SUPPORT,
    ],
  },
};

export { PERMISSIONS };
