import { CaseReducer, createSlice } from '@reduxjs/toolkit';

import { AssayFiltersShape } from 'components/views/Assays/AssaysList/AssaysFilters';
import { CompaniesFiltersShape } from 'components/views/Companies/CompaniesList/CompaniesFilters';
import { ConvertersFiltersShape } from 'components/views/Converters/ConvertersList/ConvertersFilters';
import { DashboardFiltersShape } from 'components/views/Dashboard/DashboardCharts/DashboardCharts';
import { FeedbacksFiltersShape } from 'components/views/Feedbacks/FeedbacksList/FeedbacksFilters';
import { HedgesFiltersShape } from 'components/views/Hedges/HedgesList/HedgesFilters';
import { StatisticFiltersFormShape } from 'components/views/Statistics/ConvertersStatistics/StatisticsPageFilters';
import { UsersListFiltersShape } from 'components/views/Users/UsersList/UsersFilters';
import { VehicleFiltersShape } from 'components/views/Vehicles/VehiclesList/VehicleListFilters';
import { DEFAULT_FILTERS, FilterableModules, KEYS_SKIP_FOR_DIFF } from 'shared/constants';
import { diffObjects, DiffResult } from 'shared/utils/diffObjects';

export interface FilterData<T = Payload> {
  data: T;
  diff: DiffResult<T>;
}

export interface State {
  [FilterableModules.ASSAYS]: FilterData<AssayFiltersShape>;
  [FilterableModules.COMPANIES]: FilterData<CompaniesFiltersShape>;
  [FilterableModules.CONVERTERS]: FilterData<ConvertersFiltersShape>;
  [FilterableModules.DASHBOARD]: FilterData<DashboardFiltersShape>;
  [FilterableModules.USERS]: FilterData<UsersListFiltersShape>;
  [FilterableModules.VEHICLES]: FilterData<VehicleFiltersShape>;
  [FilterableModules.HEDGES]: FilterData<HedgesFiltersShape>;
  [FilterableModules.STATISTICS]: FilterData<StatisticFiltersFormShape>;
  [FilterableModules.FEEDBACKS]: FilterData<FeedbacksFiltersShape>;
  initialFiltersForCurrentUser: Record<FilterableModules, Payload>;
}

export const initialFiltersSliceState = Object.entries(DEFAULT_FILTERS).reduce((acc, curr) => {
  acc[curr[0]] = {
    data: curr[1],
    diff: {
      diff: {},
      count: 0,
    },
  };
  return acc;
}, {} as State);

type Payload = Partial<
  | AssayFiltersShape
  | CompaniesFiltersShape
  | ConvertersFiltersShape
  | DashboardFiltersShape
  | UsersListFiltersShape
  | VehicleFiltersShape
  | HedgesFiltersShape
  | StatisticFiltersFormShape
  | FeedbacksFiltersShape
>;

const getFilterData = (key: FilterableModules, data: Payload): FilterData<Payload> => {
  const newData = {
    ...DEFAULT_FILTERS[key],
    ...data,
  };

  return {
    data: newData,
    diff: diffObjects(
      DEFAULT_FILTERS[key],
      newData,
      KEYS_SKIP_FOR_DIFF[key] as unknown as string[] & never,
    ),
  };
};

const filtersSlice = createSlice<
  State,
  {
    saveFilters: CaseReducer<
      State,
      { type: string; payload: { filterKey: FilterableModules; values: Payload } }
    >;
    saveInitialFilterForCurrentUser: CaseReducer<
      State,
      { type: string; payload: { filterKey: FilterableModules; values: Payload } }
    >;
    resetFiltersOnModuleChange: CaseReducer<State, { type: string }>;
  }
>({
  name: 'filters',
  initialState: {
    ...initialFiltersSliceState,
    initialFiltersForCurrentUser: DEFAULT_FILTERS,
  },
  reducers: {
    saveFilters: (state, { payload }) => {
      const newData = {
        ...state[payload.filterKey].data,
        ...payload.values,
      };
      return {
        ...state,
        [payload.filterKey]: getFilterData(payload.filterKey, newData),
      };
    },
    saveInitialFilterForCurrentUser: (state, { payload }) => ({
      ...state,
      [payload.filterKey]: getFilterData(payload.filterKey, payload.values),
      initialFiltersForCurrentUser: {
        ...state.initialFiltersForCurrentUser,
        [payload.filterKey]: payload.values,
      },
    }),
    resetFiltersOnModuleChange: (state): State => {
      // Reset filters to initial user values if module changed
      const filtersToReset = Object.entries(state.initialFiltersForCurrentUser);
      return {
        ...state,
        ...filtersToReset.reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: getFilterData(key as FilterableModules, value),
          }),
          {},
        ),
      };
    },
  },
});

export const { resetFiltersOnModuleChange, saveInitialFilterForCurrentUser, saveFilters } =
  filtersSlice.actions;

export default filtersSlice.reducer;
