import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// eslint-disable-next-line import/no-extraneous-dependencies
import thunk from 'redux-thunk';

import currentLanguage from './shared/currentLanguageSlice';
import filtersReducer from './shared/filtersSlice';
import modalReducer from './shared/modal';
import sidebarReducer from './shared/sidebarSlice';
import snackBarReducer from './shared/snackBarSlice';
import userProfileReducer from './shared/userProfileSlice';
import virtualKeyboardReducer from './shared/virtualKeyboardSlice';
import adjustments from './adjustmentsSlice';
import assayDetailsReducer from './assayDetailsSlice';
import assaysList from './assaysListSlice';
import auth from './auth';
import changePriceSourceReducer from './changePriceSourceSlice';
import changeUserLanguageReducer from './changeUserLanguageSlice';
import changeUserNotificationsReducer from './changeUserNotificationsSlice';
import config from './config';
import converterDetailsReducer from './converterDetailsSlice';
import fetchConvertersGroupReducer from './convertersGroupDetailsSlice';
import convertersGroupsReducer from './convertersGroupsSlice';
import convertersList from './convertersListSlice';
import createFolderReducer from './createFolderSlice';
import currencyAdjustmentReducer from './currencyAdjustmentSlice';
import downloadFileReducer from './downloadFileSlice';
import errorsReducer from './errorsSlice';
import exchangeRatesReducer from './exchangeRatesSlice';
import fetchFoldersReducer from './foldersSlice';
import hedgesReducer from './hedgesSlice';
import listComponentReducer from './listComponentSlice';
import makesSliceReducer from './makesSlice';
import metalPricesReducer from './metalPricesSlice';
import modalFormReducer from './modalFormSlice';
import notificationCount from './notificationCount';
import notificationDetails from './notificationDetails';
import notificationResolve from './notificationResolve';
import notificationsList from './notificationsList';
import partialConvertersReducer from './partialConvertersSlice';
import pricesTimestampReducer from './pricesTimestampSlice';
import removeAssayReducer from './removeAssaySlice';
import removeHedgeReducer from './removeHedgeSlice';
import termsReducer from './termsSlice';
import thresholdsUsage from './thresholdsUsageSlice';
import fetchTypesReducer from './typesSlice';
import unassignHedgeReducer from './unassignHedgeSlice';
import updateAssayReducer from './updateAssaySlice';
import updateConvertersGroupReducer from './updateConvertersGroupSlice';
import updateThemeReducer from './updateThemeSlice';
import updateUserReducer from './updateUserSlice';
import userDetailsReducer from './userDetailsSlice';
import usersList from './usersListSlice';
import HttpClient, { AlphamartReduxStore, setHttpClient } from '../shared/utils/httpClient';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['currentLanguage'],
  blacklist: ['auth'],
};

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['error'],
};

const listPersistConfig = {
  key: 'list',
  storage,
  blacklist: ['pageIndex'],
};

const appReducer = combineReducers({
  adjustments,
  assayDetails: assayDetailsReducer,
  assays: assaysList,
  auth: persistReducer(authPersistConfig, auth),
  changePriceSource: changePriceSourceReducer,
  changeUserLanguage: changeUserLanguageReducer,
  changeUserNotifications: changeUserNotificationsReducer,
  config,
  thresholdsUsage,
  converterDetails: converterDetailsReducer,
  converters: convertersList,
  convertersGroup: fetchConvertersGroupReducer,
  convertersGroups: convertersGroupsReducer,
  createFolder: createFolderReducer,
  currencyAdjustment: currencyAdjustmentReducer,
  currentLanguage,
  downloadFile: downloadFileReducer,
  errors: errorsReducer,
  exchangeRates: exchangeRatesReducer,
  filters: filtersReducer,
  folders: fetchFoldersReducer,
  hedges: hedgesReducer,
  listComponent: persistReducer(listPersistConfig, listComponentReducer),
  makes: makesSliceReducer,
  metalPrices: metalPricesReducer,
  modal: modalReducer,
  modalForm: modalFormReducer,
  notificationCount,
  notificationDetails,
  notificationResolve,
  notificationsList,
  partialConverters: partialConvertersReducer,
  pricesTimestamp: pricesTimestampReducer,
  removeAssay: removeAssayReducer,
  removeHedge: removeHedgeReducer,
  snackBar: snackBarReducer,
  theme: updateThemeReducer,
  types: fetchTypesReducer,
  unassignHedge: unassignHedgeReducer,
  updateAssay: updateAssayReducer,
  updateConvertersGroup: updateConvertersGroupReducer,
  updateUser: updateUserReducer,
  userDetails: userDetailsReducer,
  userProfile: userProfileReducer,
  users: usersList,
  virtualKeyboard: virtualKeyboardReducer,
  sidebar: sidebarReducer,
  terms: termsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'clearStore/clear') {
    // eslint-disable-next-line no-param-reassign
    state = {
      config: { ...state.config },
      position: { ...state.position },
    };
    storage.removeItem('persist:root');
    storage.removeItem('persist:auth');
    storage.removeItem('persist:list');
  }

  return appReducer(state, action);
};

export const mainReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const stores = {} as AlphamartReduxStore;
  const httpClient = new HttpClient(stores);
  setHttpClient(httpClient);
  stores.defaultStore = configureStore({
    reducer: mainReducer,
    middleware: [thunk.withExtraArgument(httpClient)],
  });
  const persistor = persistStore(stores.defaultStore);
  return { store: stores.defaultStore, persistor };
};

export type RootState = ReturnType<typeof appReducer>;
