import styled from '@emotion/styled';

import { Theme } from 'theme';

const StyledSelectedConverter = styled.div<{ theme?: Theme }>`
  font-size: 12px;
  font-weight: 700;
  display: grid;
  grid-template-columns: 70% 30%;
`;

const StyledLabel = styled.div`
  display: flex;
  padding: 40px 0 40px 32px;
`;

const StyledEdit = styled.div<{ theme?: Theme }>`
  border-left: 1px solid ${({ theme }) => theme.colors.mineShaftLighter};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledSpan = styled.span`
  padding: 0 30px;
  word-break: break-word;
`;

const StyledSection = styled.div`
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledH5 = styled.h5`
  margin: 0;
  font-size: 14px;
  font-weight: 700;
`;

export { StyledEdit, StyledH5, StyledLabel, StyledSection, StyledSelectedConverter, StyledSpan };
