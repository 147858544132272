import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';
import { Assay } from '../shared/types';

interface AssayDetailsState extends GenericStoreSlice {
  assay: Assay | null;
}

const assayDetailsSlice = createSlice<AssayDetailsState, GenericStoreReducer<AssayDetailsState>>({
  name: 'assayDetails',
  initialState: {
    assay: null,
    error: undefined,
    isPending: false,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      assay: payload,
    })),
  },
});

export const {
  pending: fetchAssayAction,
  success: fetchAssaySuccessAction,
  failure: fetchAssayFailureAction,
} = assayDetailsSlice.actions;

export const fetchAssay =
  (id: number): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(fetchAssayAction());
      const { data } = await httpClient.get(`${apiHostname}/api/assays/${id}`);
      dispatch(fetchAssaySuccessAction(data));
    } catch (error) {
      dispatch(fetchAssayFailureAction((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };

export const clearAssay = (): ReturnType<typeof fetchAssaySuccessAction> =>
  fetchAssaySuccessAction(null);

export default assayDetailsSlice.reducer;
