import React from 'react';
import { Button, Icon } from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { TypedFormattedMessage as FormattedMessage } from 'locale/messages';
import { shouldRender } from 'shared/helpers';
import { ItemAction } from 'shared/types';
import AppButton from '../forms/AppButton/AppButton';
import { ListActions } from '../List';

interface Props {
  actions?: ItemAction[];
  className?: string;
}

const ActionsButtonIcon = styled.div<{ isOpen: boolean }>`
  transition: transform 0.2s ease;
  padding: 8px;
  width: 32px;
  height: 32px;
  ${({ isOpen }) => `transform: rotate(${isOpen ? '0' : '-180'}deg);`}
`;

export function ActionsButton({ actions = [], className = '' }: Props): React.ReactElement | null {
  const availableActions = actions?.filter(shouldRender);

  return availableActions?.length > 0 ? (
    <div className={className} data-testId="action-button">
      {availableActions?.length === 1 ? (
        <Button
          label={availableActions[0].label as string}
          onClick={availableActions[0].onClick}
          data-cy={availableActions[0].dataCy?.(null)}
          content="text"
          variant="filled"
        />
      ) : (
        <ListActions
          actions={availableActions}
          renderCustomOpenButton={({ toggleMenu, isMenuOpen, dropdownRef }) => (
            <AppButton onClick={toggleMenu} ref={dropdownRef}>
              <FormattedMessage id="Global.Actions" />
              <ActionsButtonIcon isOpen={isMenuOpen}>
                <Icon name="ShevronUp" size="small" />
              </ActionsButtonIcon>
            </AppButton>
          )}
        />
      )}
    </div>
  ) : null;
}
