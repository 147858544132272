import { createSlice } from '@reduxjs/toolkit';

import { AssayFiltersShape } from 'components/views/Assays/AssaysList/AssaysFilters';
import { apiHostname } from 'shared/constants';
import { AlphamartHttpError } from 'shared/types';
import { AssayListItem } from 'shared/types/assayListItem';
import { getDateString } from 'shared/utils/getDateString';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface AssayListState extends GenericStoreSlice {
  count: number;
  list: AssayListItem[];
}

const assaysListSlice = createSlice<AssayListState, GenericStoreReducer<AssayListState>>({
  name: 'assaysList',
  initialState: {
    list: [],
    count: 0,
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      list: payload ? payload.data : [],
      count: payload ? payload.count : 0,
    })),
  },
});

export const {
  success: fetchAssaysSuccessAction,
  failure: fetchAssaysFailureAction,
  pending: fetchAssaysAction,
} = assaysListSlice.actions;

export default assaysListSlice.reducer;

export const fetchAssays =
  (page = 1, pageSize = 10, filters: AssayFiltersShape = {}): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      await dispatch(fetchAssaysAction());

      const { data } = await httpClient.get(`${apiHostname}/api/assays`, {
        params: {
          page,
          pageSize,
          query: filters.query,
          ...(typeof filters.folder === 'number' && { folder: filters.folder }),
          ...(typeof filters.type === 'number' && { type: filters.type }),
          ...(filters.removed !== 'false' && { removed: filters.removed }),
          ...(filters.picture && { picture: filters.picture }),
          ...(filters.sampleDate?.from && {
            sampleDateFrom: getDateString(filters.sampleDate.from as Date),
          }),
          ...(filters.sampleDate?.to && {
            sampleDateTo: getDateString(filters.sampleDate.to as Date),
          }),
        },
      });

      dispatch(fetchAssaysSuccessAction(data));
    } catch (error) {
      dispatch(fetchAssaysFailureAction((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };
