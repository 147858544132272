import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { VehicleFiltersShape } from 'components/views/Vehicles/VehiclesList/VehicleListFilters';
import { apiHostname } from 'shared/constants';
import { SortInterface } from 'shared/constants/sortableModules';
import { parseGetVehiclesParams } from 'shared/parsers/parseGetVehiclesParams';
import { PaginatedData, Vehicle, VehicleListItem } from 'shared/types';
import { getHttpClient } from 'shared/utils/httpClient';

export enum VehiclesKeys {
  GetVehicles = 'GetVehicles',
  GetByVin = 'GetByVin',
  GetVehicle = 'GetVehicle',
}

export interface GetVehiclesParams {
  page: number;
  pageSize: number;
  year: number | null;
  engineType: string | null;
  modelId: number | null;
  makeId: number | null;
  engineDisplacementFrom?: number | null;
  engineDisplacementTo?: number | null;
  numberOfConverters?: number | null;
  sortBy?: string;
  sortDirection?: string;
}

type GetByVinResponse = {
  makeId: number | null;
  modelId: number | null;
  year: number | null;
  engineType: string | null;
} | null;

export const useGetVehicles = (
  params: VehicleFiltersShape,
  page: number,
  pageSize: number,
  sorting: SortInterface | null,
  options: UseQueryOptions<PaginatedData<VehicleListItem>> = {},
): UseQueryResult<PaginatedData<VehicleListItem>> =>
  useQuery<PaginatedData<VehicleListItem>>(
    [VehiclesKeys.GetVehicles, params, page, pageSize, sorting],
    async () => {
      const response = await getHttpClient().get<PaginatedData<VehicleListItem>>(
        `${apiHostname}/api/vehicles`,
        { params: parseGetVehiclesParams(params, page, pageSize, sorting) },
      );
      return response.data;
    },
    options,
  );

export const useGetByVin = (
  param: string,
  options: UseQueryOptions<GetByVinResponse> = {},
): UseQueryResult<GetByVinResponse> =>
  useQuery<GetByVinResponse>(
    [VehiclesKeys.GetByVin, param],
    async () => {
      if (param.length !== 17) return null;
      const response = await getHttpClient().get<GetByVinResponse>(
        `${apiHostname}/api/vehicles/find-vin`,
        {
          params: { vin: param.toUpperCase() },
        },
      );
      return response.data;
    },
    options,
  );

export const useGetVehicle = (
  param: string,
  options: UseQueryOptions<Vehicle> = {},
): UseQueryResult<Vehicle> =>
  useQuery<Vehicle>(
    [VehiclesKeys.GetVehicle, param],
    async () => {
      const response = await getHttpClient().get<Vehicle>(`${apiHostname}/api/vehicles/${param}`);
      return response.data;
    },
    options,
  );
