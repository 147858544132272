import React, { useCallback, useState } from 'react';
import { InputSearch } from '@alpha-recycling/component-library';
import { css, keyframes } from '@emotion/css';
import { debounce, isNil } from 'lodash';
import { v4 as uuid4 } from 'uuid';

import dotsIcon from 'assets/images/icons/dots.svg';
import { useTypedIntl } from 'locale/messages';
import { useEffectAfterMount, useExtendedTheme } from 'shared/hooks';
import { usePrevious } from 'shared/hooks/usePrevious';
import { Image } from '../Buttons';

export interface SearchChangEvent {
  query?: string;
  searchSession?: string;
}
interface Props {
  onSearchChanged: (changed: SearchChangEvent) => void;
  searchAfter: number;
  initialQuery?: string;
  loadingState?: boolean;
  addSearchSession?: boolean;
}
const loadingIndicator = (theme, clearVisible) => css`
  position: absolute;
  right: ${clearVisible ? '65px' : '30px'};
  top: -2px;
  z-index: 1;

  ::after {
    content: '';
    position: absolute;
    right: 0;
    animation: ${expand} 1s 0s linear infinite;
    height: 100%;
    background: ${theme.colors.codGrayDarker};
  }
`;

const searchBarWrapper = css`
  position: relative;
`;

const expand = keyframes`
  0% { width: 100%; }
  24% { width: 100%; }
  25% { width: 60%; }
  49% { width: 60%; }
  50% { width: 40%; }
  74% { width: 40%; }
  75% { width: 0%; }
`;

export const SearchInput = ({
  loadingState,
  onSearchChanged,
  searchAfter,
  initialQuery,
  addSearchSession = false,
}: Props): React.ReactElement => {
  const intl = useTypedIntl();
  const theme = useExtendedTheme();
  const onSearchChangedDebounce = useCallback(debounce(onSearchChanged, 700), [onSearchChanged]);
  const [query, setQuery] = useState(initialQuery ?? '');
  const [searchSession, setSearchSession] = useState(uuid4());
  const [keyRepeats, setKeyRepeats] = useState({ key: '', repeats: 0 });
  const previousQuery = usePrevious(query);

  const handleKeyDown = (e?: React.KeyboardEvent<HTMLInputElement>) => {
    if (!e) return;
    setKeyRepeats(({ key, repeats }) => ({ key: e.key, repeats: e.key === key ? repeats + 1 : 1 }));
  };

  const searchChangeHandler = (e?: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e?.target.value ?? '');
  };

  useEffectAfterMount(() => {
    const session =
      query.includes(previousQuery ?? '') || keyRepeats.repeats > 1 ? searchSession : uuid4();

    if (
      query.length >= searchAfter ||
      (query.length === 0 && !isNil(previousQuery) && previousQuery.length > 0)
    ) {
      onSearchChangedDebounce({
        query,
        ...(addSearchSession && { searchSession: session }),
      });
    }

    setSearchSession(session);
  }, [query]);

  const clearQuery = useCallback(() => {
    setQuery('');
    setSearchSession(uuid4());
  }, [query, setQuery, setSearchSession, onSearchChanged]);

  return (
    <div className={searchBarWrapper} data-cy="search-bar-wrapper">
      <InputSearch
        name="search"
        onChange={searchChangeHandler}
        onKeyDown={handleKeyDown}
        placeholder={intl.formatMessage({ id: 'Global.Search' })}
        data-cy="search-input"
        handleCleanUp={() => query && clearQuery()}
        value={query}
      />
      {loadingState && (
        <Image
          className={loadingIndicator(theme, !!query)}
          color={theme.colors.mineShaftLightest}
          name={dotsIcon}
          size="48px"
        />
      )}
    </div>
  );
};
