export enum ModalFormType {
  HedgeLogs = 'HEDGE_LOGS',
  ConverterLogs = 'CONVERTER_LOGS',
  UsageLimit = 'USAGE_LIMIT',
  AccessList = 'ACCESS_LIST',
  VinSearch = 'VIN_SEARCH',
  AssignHedge = 'ASSIGN_HEDGE',
  UserConverterLogs = 'USER_CONVERTER_LOGS',
  UserHedgeLogs = 'USER_HEDGE_LOGS',
  AssignedVehicles = 'ASSIGNED_VEHICLES',
  RenameShoppingCart = 'RENAME_SHOPPING_CART',
  AddShoppingCartItems = 'ADD_SHOPPING_CART_ITEMS',
  EditShoppingCartItem = 'EDIT_SHOPPING_CART_ITEM',
  DownloadShoppingCartActivityLogs = 'DOWNLOAD_SHOPPING_CART_ACTIVITY_LOGS',
  MarketPriceRange = 'MARKET_PRICE_RANGE',
  GoToPreference = 'GO_TO_PREFERENCE',
}
