import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { parseUser } from 'shared/parsers/parseUser';
import { AlphamartHttpError, ManageUserAccesses, User, UserFormData } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface UpdateUserState extends GenericStoreSlice {
  user: User | undefined;
}

const updateUserSlice = createSlice<UpdateUserState, GenericStoreReducer<UpdateUserState>>({
  name: 'updateUser',
  initialState: {
    isPending: false,
    user: undefined,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => (payload ? { user: payload.user } : { user: null })),
  },
});

export const {
  pending: updateUserAction,
  success: updateUserSuccessAction,
  failure: updateUserFailureAction,
} = updateUserSlice.actions;

export const updateUser =
  (values: UserFormData, id: number, accesses: ManageUserAccesses): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(updateUserAction());

      const user = await httpClient.put(
        `${apiHostname}/api/users/${id}`,
        parseUser(values, accesses),
      );

      dispatch(updateUserSuccessAction({ user }));
    } catch (error) {
      dispatch(updateUserFailureAction());
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject((error as AlphamartHttpError)?.response?.data);
    }
  };

export default updateUserSlice.reducer;
