import { createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, ListModule, Make } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface MakesState extends GenericStoreSlice {
  makes: Make[];
}

interface FetchMakesFiltersParam {
  module?: ListModule;
}

const fetchMakesSlice = createSlice<MakesState, GenericStoreReducer<MakesState>>({
  name: 'makes',
  initialState: {
    makes: [],
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      makes: payload,
    })),
  },
});

export const {
  pending: fetchMakesAction,
  success: fetchMakesSuccessAction,
  failure: fetchMakesFailureAction,
} = fetchMakesSlice.actions;

export const fetchMakes =
  (filters: FetchMakesFiltersParam = {}): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      dispatch(fetchMakesAction());
      const makes = await httpClient.get(`${apiHostname}/api/makes`, { params: filters });
      dispatch(fetchMakesSuccessAction(makes.data));
    } catch (error) {
      dispatch(fetchMakesFailureAction((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };

export default fetchMakesSlice.reducer;
