import React, { useMemo } from 'react';
import { Button } from '@alpha-recycling/component-library';

import { useRemoveFeedbacks } from 'shared/mutations';
import { useAppDispatch } from 'store/shared/hooks';
import { hideModal, showModal } from 'store/shared/modal';
import { snackBarPushFailure, snackBarPushSuccess } from 'store/shared/snackBarSlice';
import { RemoveFeedbacksContainer, SelectRemoveButton } from './FeedbacksList.styles';
import { TypedFormattedMessage, useTypedIntl } from '../locale/messages';

interface Props {
  enableSelection: boolean;
  setEnableSelection: (value: boolean) => void;
  selection: Record<string, boolean>;
  resetSelection: () => void;
  removeFeedbacks: ReturnType<typeof useRemoveFeedbacks>;
  onRemove: () => void;
}

export const RemoveFeedbacks = ({
  enableSelection,
  setEnableSelection,
  selection,
  resetSelection,
  removeFeedbacks,
  onRemove,
}: Props) => {
  const intl = useTypedIntl();
  const dispatch = useAppDispatch();

  const exitSelection = () => {
    resetSelection();
    setEnableSelection(false);
  };

  const selectedIds = useMemo(
    () =>
      Object.keys(selection)
        .filter(key => selection[key])
        .map(Number),
    [selection],
  );

  const onRemoveCallback = async () => {
    const messageType = selectedIds.length > 1 ? 'Plural' : 'Singular';
    dispatch(
      showModal({
        message: intl.formatMessage({
          id: `FeedbacksList.RemoveFeedbacks.RemoveSelected.${messageType}`,
        }),
        onClose: () => {
          dispatch(hideModal());
        },
        onConfirm: async () => {
          dispatch(hideModal());

          try {
            await removeFeedbacks.mutateAsync(selectedIds);
            dispatch(
              snackBarPushSuccess(
                intl.formatMessage({
                  id: `FeedbacksList.RemoveFeedbacks.SuccessMessage.${messageType}`,
                }),
              ),
            );
            onRemove();
          } catch (e) {
            dispatch(
              snackBarPushFailure(intl.formatMessage({ id: 'Global.Error.SomethingWentWrong' })),
            );
          }
        },
      }),
    );
  };

  if (enableSelection) {
    return (
      <RemoveFeedbacksContainer>
        <span data-testId="selected-count-message">
          <TypedFormattedMessage
            id="FeedbacksList.RemoveFeedbacks.SelectedCount"
            values={{ count: selectedIds.length }}
          />
        </span>
        <Button
          variant="transparent"
          content="text"
          onClick={exitSelection}
          label={intl.formatMessage({
            id: `Global.Cancel`,
          })}
          data-testId="cancel-remove-feedbacks-button"
        />
        <Button
          variant="filled"
          content="text"
          onClick={onRemoveCallback}
          label={intl.formatMessage({
            id: `FeedbacksList.RemoveFeedbacks.RemoveSelected`,
          })}
          data-testId="remove-feedbacks-button"
          disabled={!selectedIds.length}
        />
      </RemoveFeedbacksContainer>
    );
  }

  return (
    <SelectRemoveButton>
      <Button
        variant="plain"
        iconName="Check"
        data-testId="select-remove-feedbacks"
        onClick={() => setEnableSelection(true)}
        label={intl.formatMessage({
          id: `FeedbacksList.RemoveFeedbacks.SelectForRemoval`,
        })}
      />
    </SelectRemoveButton>
  );
};
