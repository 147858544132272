import { CaseReducer, createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, ConvertersGroup } from 'shared/types';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

interface FetchConvertersGroupSlice extends GenericStoreSlice {
  convertersGroups: ConvertersGroup[];
}

type FetchConvertersGroupReducer = GenericStoreReducer<FetchConvertersGroupSlice> & {
  clearConvertersGroups: CaseReducer<FetchConvertersGroupSlice, { type: string }>;
};

const fetchConvertersGroupsSlice = createSlice<
  FetchConvertersGroupSlice,
  FetchConvertersGroupReducer
>({
  name: 'convertersGroups',
  initialState: {
    convertersGroups: [],
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      convertersGroups: payload,
    })),
    clearConvertersGroups: state => ({
      ...state,
      convertersGroups: [],
    }),
  },
});

export const {
  pending: fetchConvertersGroupsAction,
  success: fetchConvertersGroupsSuccessAction,
  failure: fetchConvertersGroupsFailureAction,
  clearConvertersGroups,
} = fetchConvertersGroupsSlice.actions;

export const fetchConvertersGroups = (): GenericThunk => async (dispatch, getState, httpClient) => {
  try {
    dispatch(fetchConvertersGroupsAction());

    const convertersGroups = await httpClient.get(`${apiHostname}/api/converters/groups`);
    dispatch(fetchConvertersGroupsSuccessAction(convertersGroups.data));
  } catch (error) {
    dispatch(
      fetchConvertersGroupsFailureAction((error as AlphamartHttpError)?.response?.data.message),
    );
    return Promise.reject(error);
  }
};

export default fetchConvertersGroupsSlice.reducer;
