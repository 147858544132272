import { CaseReducer, createSlice } from '@reduxjs/toolkit';

import { apiHostname } from 'shared/constants';
import { parseProfitMargins, ParseProfitMarginsParams } from 'shared/parsers/parseProfitMargins';
import { User } from 'shared/types';
import { GenericThunk } from './shared/createGenericStoreSlice';

export interface AuthState {
  user: { data: User } | null;
  error: {
    message: string;
    code?: string;
  } | null;
  isPending: boolean;
}

type AuthReducer = {
  loginSuccess: CaseReducer<AuthState, { type: string; payload: { data: User } }>;
  submitting: CaseReducer<AuthState, { type: string }>;
  failure: CaseReducer<
    AuthState,
    { type: string; payload: { message: string; code?: string } | null }
  >;
};

export interface UpdateCurrentProfitMarginParams {
  currentProfitMarginPt: number;
  currentProfitMarginPd: number;
  currentProfitMarginRh: number;
  saveSelectedProfitMargin: boolean;
  selectedProfitMargin: number;
}

const authSlice = createSlice<AuthState, AuthReducer>({
  name: 'auth',
  initialState: {
    user: null,
    error: null,
    isPending: false,
  },
  reducers: {
    loginSuccess: (state, { payload }) => ({
      ...state,
      user: payload,
      error: null,
      isPending: false,
    }),
    submitting: state => ({
      ...state,
      isPending: true,
      error: null,
    }),
    failure: (state, { payload }) => ({
      ...state,
      user: null,
      error: payload,
      isPending: false,
    }),
  },
});

export const { loginSuccess, failure, submitting } = authSlice.actions;

export default authSlice.reducer;

export const fetchUser =
  (suppressStoreEvents = false): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      if (!suppressStoreEvents) dispatch(submitting());
      const user = await httpClient.get<User>(`${apiHostname}/api/me`);
      dispatch(loginSuccess(user));
    } catch (err) {
      dispatch(failure(null));
    }
  };

export const updateUserCurrentProfitMargin =
  (data: UpdateCurrentProfitMarginParams, parseParams: ParseProfitMarginsParams): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    const parsedData = parseProfitMargins(data, parseParams);
    await httpClient.put(`${apiHostname}/api/me/current-profit-margin`, parsedData);
  };
