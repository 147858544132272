import React from 'react';
import {
  DataOutput,
  OutputCell,
  Status,
  STATUS_TYPE_VALUES,
} from '@alpha-recycling/component-library';
import styled from '@emotion/styled';

import { TextButton } from 'components/shared/Buttons';
import { Details } from 'components/shared/Details';
import { LoadableContent } from 'components/shared/Loader';
import { TagGroup } from 'components/shared/TagGroup';
import { getUserTypeLang } from 'helpers/userTypes/userTypes';
import { AccessFlag, PERMISSIONS, SHARED, UserTypes } from 'shared/constants';
import { useAuthorization } from 'shared/helpers';
import { useAlphamartNavigate, useCurrentUser, useUserActions } from 'shared/hooks';
import { useGetDevices } from 'shared/queries';
import { Status as StatusTypes, UsageThresholds, User } from 'shared/types';
import { theme as mainTheme } from 'theme';
import { UserDetailsDevices } from './UserDetailsDevices';
import { UserDetailsHedges } from './UserDetailsHedges';
import { UserDetailsLastLogin } from './UserDetailsLastLogin';
import { UserDetailsProfitMargins } from './UserDetailsProfitMargins';
import { UserDetailsUsageThresholds } from './UserDetailsThresholds';
import { useUserItemActions } from '../hooks/useUserItemActions';
import { UsersMessages, useTypedIntl } from '../locale/messages';

const StyledLink = styled.a`
  color: ${mainTheme.mainColor};
`;

type Props = {
  user?: User;
};

const UserDetails = ({ user }: Props): React.ReactElement | null => {
  const intl = useTypedIntl();
  const authorize = useAuthorization();
  const navigate = useAlphamartNavigate();
  const currentUser = useCurrentUser();
  const { data: detailsDevices } = useGetDevices(
    { userId: user?.id, status: [StatusTypes.ACTIVE, StatusTypes.INACTIVE] },
    { initialData: [] },
  );
  const canNavigateToCompanyDetails = authorize(PERMISSIONS.COMPANIES.LIST);
  const canSeeDevicesDetails = authorize(PERMISSIONS.USERS.DETAILS.SHOW_USER_DEVICES_DETAILS);
  const canSeeUserTermsAdjustments =
    authorize(PERMISSIONS.USERS.DETAILS.SHOW_USER_TERMS_ADJUSTMENTS_FIELD) &&
    user?.userTypes.some(({ name }) => name === UserTypes.BUYER);
  const canSeeDisplayHedgePrices = authorize(PERMISSIONS.USERS.SHOW_DISPLAY_HEDGE_PRICES_FIELD);
  const canSeeUsageThresholds = authorize(PERMISSIONS.USER_PROFILE.EDIT_USAGES_THRESHOLDS);
  const { usersThresholds } = useCurrentUser();
  const filterUser: UsageThresholds | undefined = usersThresholds?.find(
    (item: UsageThresholds) => item.assignedTo === user?.id,
  );

  const { isPending: areUserActionsPending } = useUserActions();

  const actions = useUserItemActions(user);

  if (!user) return null;

  const colorCalc = status => {
    switch (status) {
      case StatusTypes.ACTIVE:
        return STATUS_TYPE_VALUES.active;
      case StatusTypes.INACTIVE:
        return STATUS_TYPE_VALUES.inactive;
      case StatusTypes.PENDING:
        return STATUS_TYPE_VALUES.pending;
      default:
        return STATUS_TYPE_VALUES.unread;
    }
  };

  const userTypesList = (
    <TagGroup
      items={user?.userTypes?.map(userType => getUserTypeLang(userType.name, intl))}
      countLabelFormatter={count => intl.formatMessage({ id: 'Global.Roles' }, { count })}
      size="small"
    />
  );

  return (
    <LoadableContent loading={areUserActionsPending} mode={LoadableContent.MODE.FULL} drawContent>
      <Details title={`${user.firstName} ${user.lastName}`} backUrl="/users" actions={actions}>
        <DataOutput
          headerText={intl.formatMessage({
            id: 'UserDetails.Title',
          })}
        >
          {[
            {
              name: 'userName',
              label: intl.formatMessage({
                id: 'UserDetails.UserName',
              }),
              value: `${user.firstName} ${user.lastName}`,
            },
            {
              name: 'status',
              label: intl.formatMessage({
                id: 'UserDetails.Status',
              }),
              value: (
                <Status
                  type={colorCalc(user.status)}
                  value={intl.formatMessage({
                    id: `Global.Status.${user.status?.toUpperCase()}` as keyof UsersMessages,
                  })}
                  size="small"
                  discrete
                />
              ),
            },
            {
              name: 'userType',
              label: intl.formatMessage({
                id: 'UserDetails.UserType',
              }),
              value: userTypesList,
            },
            {
              name: 'email',
              label: intl.formatMessage({
                id: 'UserDetails.Email',
              }),
              value: <StyledLink href={`mailto:${user.email}`}>{user.email}</StyledLink>,
            },
            {
              name: 'company',
              label: intl.formatMessage({
                id: 'UserDetails.Company',
              }),
              value: canNavigateToCompanyDetails ? (
                <TextButton onClick={() => navigate(`/companies/${user.company?.id}`)}>
                  {user.company?.name}
                </TextButton>
              ) : (
                user.company?.name
              ),
            },
            {
              name: 'mobilePhone',
              label: intl.formatMessage({
                id: 'UserDetails.MobilePhone',
              }),
              value: `${user.mobilePhonePrefix} ${user.mobilePhone}`,
            },
            {
              name: 'officePhone',
              label: intl.formatMessage({
                id: 'UserDetails.OfficePhone',
              }),
              value: user.officePhone
                ? `${user.officePhonePrefix} ${user.officePhone}`
                : SHARED.LONG_DASH,
            },
          ].map((field, index, fields) => (
            <OutputCell
              key={field.name}
              data-cy={field.name}
              mergeCells={fields.length - 1 === index ? 4 : 2}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>

        <UserDetailsProfitMargins user={user} />

        {canSeeUserTermsAdjustments && (
          <DataOutput
            headerText={intl.formatMessage({
              id: 'UserDetails.TermsAdjustments',
            })}
          >
            {[
              {
                name: 'ptTermsAdjustment',
                label: intl.formatMessage({
                  id: 'Global.Metals.Platinum',
                }),
                value: `${user.ptTermsAdjustment} %`,
              },
              {
                name: 'pdTermsAdjustment',
                label: intl.formatMessage({
                  id: 'Global.Metals.Palladium',
                }),
                value: `${user.pdTermsAdjustment} %`,
              },
              {
                name: 'rhTermsAdjustment',
                label: intl.formatMessage({
                  id: 'Global.Metals.Rhodium',
                }),
                value: `${user.rhTermsAdjustment} %`,
              },
            ].map((field, index, fields) => (
              <OutputCell
                key={field.name}
                data-cy={field.name}
                mergeCells={fields.length - 1 === index ? 4 : 2}
                labelValue={field.label}
              >
                {field.value}
              </OutputCell>
            ))}
          </DataOutput>
        )}

        <UserDetailsHedges user={user} />

        <DataOutput
          headerText={intl.formatMessage({
            id: 'UserDetails.Privileges',
          })}
        >
          {[
            {
              name: 'additionalDevice',
              label: intl.formatMessage({
                id: 'UserDetails.additionalDevice',
              }),
              value: intl.formatMessage({
                id: `Global.${user?.privileges?.additionalDevice ? 'Yes' : 'No'}`,
              }),
            },
          ].map(field => (
            <OutputCell
              key={field.name}
              data-cy={field.name}
              mergeCells={4}
              labelValue={field.label}
            >
              {field.value}
            </OutputCell>
          ))}
        </DataOutput>

        {canSeeDisplayHedgePrices ? (
          <DataOutput
            headerText={intl.formatMessage({
              id: 'UserDetails.Hedges',
            })}
          >
            {[
              {
                name: 'displayHedgePrices',
                label: intl.formatMessage({
                  id: 'UserDetails.DisplayHedgePrices',
                }),
                value: intl.formatMessage({
                  id: `Global.${
                    user.accessFlags.includes(AccessFlag.HEDGE_PRICE_VIEWER) ? 'Yes' : 'No'
                  }`,
                }),
              },
            ].map(field => (
              <OutputCell
                key={field.name}
                data-cy={field.name}
                mergeCells={4}
                labelValue={field.label}
              >
                {field.value}
              </OutputCell>
            ))}
          </DataOutput>
        ) : null}

        {canSeeDevicesDetails && detailsDevices && detailsDevices.length > 0 ? (
          <UserDetailsDevices userDevices={detailsDevices} />
        ) : null}

        {user.loginLogs && user.loginLogs.length > 0 && (
          <UserDetailsLastLogin userLoginLog={user.loginLogs[0]} />
        )}

        {canSeeUsageThresholds && user.id !== currentUser?.id ? (
          <UserDetailsUsageThresholds user={filterUser} />
        ) : null}
      </Details>
    </LoadableContent>
  );
};

export { UserDetails };
