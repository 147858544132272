import { createSlice } from '@reduxjs/toolkit';
import { CancelTokenSource } from 'axios';

import { apiHostname } from 'shared/constants';
import { AlphamartHttpError, ConverterListItem } from 'shared/types';
import { getDateString } from 'shared/utils/getDateString';
import {
  GenericStoreReducer,
  GenericStoreSlice,
  GenericThunk,
  getGenericReducers,
} from './shared/createGenericStoreSlice';

let cancelTokenSource: CancelTokenSource;

interface ConvertersListState extends GenericStoreSlice {
  count: number;
  list: ConverterListItem[];
}

interface FetchConvertersFiltersParam {
  query?: string;
  picture?: string | null;
  years?: {
    from: string | null;
    to: string | null;
  };
  addedAt?: {
    from: Date | null;
    to: Date | null;
  };
  makeId?: number | null;
  modelId?: number | null;
  converterType?: number | null;
  showComingSoon?: boolean;
  showCounterfeit?: boolean;
  searchSession?: string;
}

const convertersListSlice = createSlice<
  ConvertersListState,
  GenericStoreReducer<ConvertersListState>
>({
  name: 'convertersList',
  initialState: {
    list: [],
    count: 0,
    isPending: false,
    error: undefined,
  },
  reducers: {
    ...getGenericReducers(payload => ({
      list: payload ? payload.data : [],
      count: payload ? payload.count : 0,
    })),
  },
});

export const {
  success: fetchConvertersSuccessAction,
  failure: fetchConvertersFailureAction,
  pending: fetchConvertersAction,
} = convertersListSlice.actions;

export default convertersListSlice.reducer;

export const fetchConverters =
  (page = 1, pageSize = 12, filters: FetchConvertersFiltersParam = {}): GenericThunk =>
  async (dispatch, getState, httpClient) => {
    try {
      cancelTokenSource?.cancel('Concurrent operation canceled');
      cancelTokenSource = httpClient.getCancelToken();

      dispatch(fetchConvertersAction());

      const { data } = await httpClient.get(`${apiHostname}/api/converters`, {
        params: {
          page,
          pageSize,
          query: filters.query ?? '',
          ...(filters.makeId && { makeId: filters.makeId }),
          ...(filters.modelId && { modelId: filters.modelId }),
          converterType: filters.converterType,
          searchSession: filters.searchSession,
          comingSoon: filters.showComingSoon ?? false,
          showCounterfeit: filters.showCounterfeit ?? false,
          ...(filters.picture && { picture: filters.picture }),
          ...(filters.years?.from && { yearFrom: filters.years?.from }),
          ...(filters.years?.to && { yearTo: filters.years?.to }),
          ...(filters.addedAt?.from && { addedFrom: getDateString(filters.addedAt.from) }),
          ...(filters.addedAt?.to && { addedTo: getDateString(filters.addedAt.to) }),
        },
        cancelToken: cancelTokenSource.token,
      });
      dispatch(fetchConvertersSuccessAction(data));
    } catch (error) {
      if (httpClient.isCancel(error)) return;

      dispatch(fetchConvertersFailureAction((error as AlphamartHttpError)?.response?.data.message));
      return Promise.reject(error);
    }
  };
